import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    file: null,
    ratio: 1,
};

export const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        setFile: (state, action) => {
            state.file = action.payload;
        },
        setRatio: (state, action) => {
            state.ratio = action.payload;
        },
        resetFile: (state) => {
            state.file = null;
        }
    },
});

export const { setFile, setRatio, resetFile} = fileSlice.actions;

export default fileSlice.reducer;
