import { Link } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../utils/theme';
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export default function LanguageSwitcher() {
    const { i18n } = useTranslation();

    const l = i18n.language;
    const en = window.location.pathname.replace('/' + l + '/', '/en/');
    const ru = window.location.pathname.replace('/' + l + '/', '/ru/');
    const ua = window.location.pathname.replace('/' + l + '/', '/ua/');
    const ro = window.location.pathname.replace('/' + l + '/', '/ro/');

    return (
        <ThemeProvider theme={theme}>
        <div>
            <Stack direction="row" justifyContent={"end"} spacing={1}>
            <Link to={en}><Button sx={{width:'30px',minWidth:'30px'}} size="medium" variant="text" color={i18n.language === 'en' ? 'primary' : 'secondary'}>EN</Button></Link>
            <Link to={ru}><Button sx={{width:'30px',minWidth:'30px'}} size="medium" variant="text" color={i18n.language === 'ru' ? 'primary' : 'secondary'}>RU</Button></Link>
            <Link to={ua}><Button sx={{width:'30px',minWidth:'30px'}} size="medium" variant="text" color={i18n.language === 'ua' ? 'primary' : 'secondary'}>UА</Button></Link>
            <Link to={ro}><Button sx={{width:'30px',minWidth:'30px'}} size="medium" variant="text" color={i18n.language === 'ro' ? 'primary' : 'secondary'}>RO</Button></Link>
            </Stack>
        </div>
        </ThemeProvider>
    );
}
