const DMC = {
    "#d1d0cd": {"code": "1", "name": "White Tin", "hex": "#d1d0cd", "R": 209, "G": 208, "B": 205, "h": 97.01, "x": 0},
    "#bebebd": {"code": "2", "name": "Tin", "hex": "#bebebd", "R": 190, "G": 190, "B": 189, "h": 110.13, "x": 0},
    "#97989c": {"code": "3", "name": "Tin Medium", "hex": "#97989c", "R": 151, "G": 152, "B": 156, "h": 280.76, "x": 0},
    "#747275": {"code": "4", "name": "Tin Dark", "hex": "#747275", "R": 116, "G": 114, "B": 117, "h": 314.77, "x": 0},
    "#c5b7ad": {"code": "5", "name": "Driftwood Lt", "hex": "#c5b7ad", "R": 197, "G": 183, "B": 173, "h": 64.61, "x": 0},
    "#bdaea3": {"code": "6", "name": "Driftwood Med Lt", "hex": "#bdaea3", "R": 189, "G": 174, "B": 163, "h": 65.19, "x": 0},
    "#8f7d6f": {"code": "7", "name": "Driftwood", "hex": "#8f7d6f", "R": 143, "G": 125, "B": 111, "h": 65.94, "x": 0},
    "#584437": {"code": "8", "name": "Driftwood Dk", "hex": "#584437", "R": 88, "G": 68, "B": 55, "h": 59.81, "x": 0},
    "#412e30": {"code": "9", "name": "Cocoa very Dk", "hex": "#412e30", "R": 65, "G": 46, "B": 48, "h": 13.27, "x": 0},
    "#eae2bd": {"code": "10", "name": "Tender Green Very Lt", "hex": "#eae2bd", "R": 234, "G": 226, "B": 189, "h": 99.52, "x": 0},
    "#e8d77c": {"code": "11", "name": "Tender Green Lt", "hex": "#e8d77c", "R": 232, "G": 215, "B": 124, "h": 97.52, "x": 0},
    "#d9c854": {"code": "12", "name": "Tender Green", "hex": "#d9c854", "R": 217, "G": 200, "B": 84, "h": 97.67, "x": 0},
    "#b9d39f": {"code": "13", "name": "Nile Green Med Lt", "hex": "#b9d39f", "R": 185, "G": 211, "B": 159, "h": 127.93, "x": 0},
    "#dfe2a5": {"code": "14", "name": "Apple Green Pale", "hex": "#dfe2a5", "R": 223, "G": 226, "B": 165, "h": 109.7, "x": 0},
    "#d2d48d": {"code": "15", "name": "Apple Green", "hex": "#d2d48d", "R": 210, "G": 212, "B": 141, "h": 108.2, "x": 0},
    "#c2c65b": {"code": "16", "name": "Chartreuse Lt", "hex": "#c2c65b", "R": 194, "G": 198, "B": 91, "h": 106.91, "x": 0},
    "#e9c65f": {"code": "17", "name": "Yellow Plum Lt", "hex": "#e9c65f", "R": 233, "G": 198, "B": 95, "h": 89.42, "x": 0},
    "#e1ba44": {"code": "18", "name": "Yellow Plum", "hex": "#e1ba44", "R": 225, "G": 186, "B": 68, "h": 88.35, "x": 0},
    "#fbc07c": {"code": "19", "name": "Autumn Gold med Lt", "hex": "#fbc07c", "R": 251, "G": 192, "B": 124, "h": 72.49, "x": 0},
    "#f7c0af": {"code": "20", "name": "Shrimp", "hex": "#f7c0af", "R": 247, "G": 192, "B": 175, "h": 42.95, "x": 0},
    "#b26556": {"code": "21", "name": "Alizarin Lt", "hex": "#b26556", "R": 178, "G": 101, "B": 86, "h": 36.84, "x": 0},
    "#832725": {"code": "22", "name": "Alizarin", "hex": "#832725", "R": 131, "G": 39, "B": 37, "h": 31.13, "x": 0},
    "#f9eaea": {"code": "23", "name": "Apple Blossom", "hex": "#f9eaea", "R": 249, "G": 234, "B": 234, "h": 19.78, "x": 0},
    "#e9dee4": {"code": "24", "name": "White Lavender", "hex": "#e9dee4", "R": 233, "G": 222, "B": 228, "h": 341.65, "x": 0},
    "#dbd5e0": {"code": "25", "name": "Lavender Ultra Lt", "hex": "#dbd5e0", "R": 219, "G": 213, "B": 224, "h": 310.85, "x": 0},
    "#c9c8da": {"code": "26", "name": "Lavender Pale", "hex": "#c9c8da", "R": 201, "G": 200, "B": 218, "h": 293.12, "x": 0},
    "#e2dfe1": {"code": "27", "name": "White Violet", "hex": "#e2dfe1", "R": 226, "G": 223, "B": 225, "h": 335.76, "x": 0},
    "#797284": {"code": "28", "name": "Eggplant Med Lt", "hex": "#797284", "R": 121, "G": 114, "B": 132, "h": 305.78, "x": 0},
    "#3c2e44": {"code": "29", "name": "Eggplant", "hex": "#3c2e44", "R": 60, "G": 46, "B": 68, "h": 314.92, "x": 0},
    "#7e7d9e": {"code": "30", "name": "Blueberry Med Lt", "hex": "#7e7d9e", "R": 126, "G": 125, "B": 158, "h": 293.56, "x": 0},
    "#5c5b80": {"code": "31", "name": "Blueberry", "hex": "#5c5b80", "R": 92, "G": 91, "B": 128, "h": 294.42, "x": 0},
    "#3e4063": {"code": "32", "name": "Blueberry Dk", "hex": "#3e4063", "R": 62, "G": 64, "B": 99, "h": 292.56, "x": 0},
    "#814a7e": {"code": "33", "name": "Fuschia", "hex": "#814a7e", "R": 129, "G": 74, "B": 126, "h": 328.23, "x": 0},
    "#68175c": {"code": "34", "name": "Fuschia Dark", "hex": "#68175c", "R": 104, "G": 23, "B": 92, "h": 333.36, "x": 0},
    "#501141": {"code": "35", "name": "Fuschia Vy Dk", "hex": "#501141", "R": 80, "G": 17, "B": 65, "h": 337.32, "x": 0},
    "#751333": {"code": "150", "name": "Dusty Rose Ult Vy Dk", "hex": "#751333", "R": 117, "G": 19, "B": 51, "h": 9.35, "x": 0},
    "#e8bbc4": {"code": "151", "name": "Dusty Rose Vry Lt", "hex": "#e8bbc4", "R": 232, "G": 187, "B": 196, "h": 5.24, "x": 0},
    "#c39594": {"code": "152", "name": "Shell Pink Med Light", "hex": "#c39594", "R": 195, "G": 149, "B": 148, "h": 22.88, "x": 0},
    "#d0bbd3": {"code": "153", "name": "Violet Very Light", "hex": "#d0bbd3", "R": 208, "G": 187, "B": 211, "h": 321.54, "x": 0},
    "#25031f": {"code": "154", "name": "Grape Very Dark", "hex": "#25031f", "R": 37, "G": 3, "B": 31, "h": 333.91, "x": 0},
    "#787fb2": {"code": "155", "name": "Blue Violet Med Dark", "hex": "#787fb2", "R": 120, "G": 127, "B": 178, "h": 289.15, "x": 0},
    "#8197ba": {"code": "156", "name": "Blue Violet Med Lt", "hex": "#8197ba", "R": 129, "G": 151, "B": 186, "h": 271.49, "x": 0},
    "#a5bbcf": {"code": "157", "name": "Cornflower Blue Vy Lt", "hex": "#a5bbcf", "R": 165, "G": 187, "B": 207, "h": 255.63, "x": 0},
    "#2b406c": {"code": "158", "name": "Cornflower Blu M V D", "hex": "#2b406c", "R": 43, "G": 64, "B": 108, "h": 282.89, "x": 0},
    "#a6adbe": {"code": "159", "name": "Blue Gray Light", "hex": "#a6adbe", "R": 166, "G": 173, "B": 190, "h": 275.95, "x": 0},
    "#7787a0": {"code": "160", "name": "Blue Gray Medium", "hex": "#7787a0", "R": 119, "G": 135, "B": 160, "h": 270.1, "x": 0},
    "#51607c": {"code": "161", "name": "Blue Gray", "hex": "#51607c", "R": 81, "G": 96, "B": 124, "h": 275.17, "x": 0},
    "#c8dbdf": {"code": "162", "name": "Blue Ultra Very Light", "hex": "#c8dbdf", "R": 200, "G": 219, "B": 223, "h": 216.67, "x": 0},
    "#557a60": {"code": "163", "name": "Celadon Green Md", "hex": "#557a60", "R": 85, "G": 122, "B": 96, "h": 151.84, "x": 0},
    "#a6b784": {"code": "164", "name": "Forest Green Lt", "hex": "#a6b784", "R": 166, "G": 183, "B": 132, "h": 121.52, "x": 0},
    "#dac774": {"code": "165", "name": "Moss Green Vy Lt", "hex": "#dac774", "R": 218, "G": 199, "B": 116, "h": 95.72, "x": 0},
    "#b9a216": {"code": "166", "name": "Moss Green Md Lt", "hex": "#b9a216", "R": 185, "G": 162, "B": 22, "h": 94.06, "x": 0},
    "#956b3a": {"code": "167", "name": "Yellow Beige V Dk", "hex": "#956b3a", "R": 149, "G": 107, "B": 58, "h": 71.6, "x": 0},
    "#b6bdbd": {"code": "168", "name": "Pewter Very Light", "hex": "#b6bdbd", "R": 182, "G": 189, "B": 189, "h": 199.21, "x": 0},
    "#7d8482": {"code": "169", "name": "Pewter Light", "hex": "#7d8482", "R": 125, "G": 132, "B": 130, "h": 176.5, "x": 0},
    "#6f5497": {"code": "208", "name": "Lavender Very Dark", "hex": "#6f5497", "R": 111, "G": 84, "B": 151, "h": 308.19, "x": 0},
    "#9583b4": {"code": "209", "name": "Lavender Dark", "hex": "#9583b4", "R": 149, "G": 131, "B": 180, "h": 305.89, "x": 0},
    "#b2a5cd": {"code": "210", "name": "Lavender Medium", "hex": "#b2a5cd", "R": 178, "G": 165, "B": 205, "h": 303.92, "x": 0},
    "#ccc2de": {"code": "211", "name": "Lavender Light", "hex": "#ccc2de", "R": 204, "G": 194, "B": 222, "h": 304.62, "x": 0},
    "#631c21": {"code": "221", "name": "Shell Pink Vy Dk", "hex": "#631c21", "R": 99, "G": 28, "B": 33, "h": 24.67, "x": 0},
    "#995e65": {"code": "223", "name": "Shell Pink Light", "hex": "#995e65", "R": 153, "G": 94, "B": 101, "h": 14, "x": 0},
    "#d3a9a3": {"code": "224", "name": "Shell Pink Very Light", "hex": "#d3a9a3", "R": 211, "G": 169, "B": 163, "h": 31.73, "x": 0},
    "#eecfc9": {"code": "225", "name": "Shell Pink Ult Vy Lt", "hex": "#eecfc9", "R": 238, "G": 207, "B": 201, "h": 35.22, "x": 0},
    "#4a1b00": {"code": "300", "name": "Mahogany Vy Dk", "hex": "#4a1b00", "R": 74, "G": 27, "B": 0, "h": 50.31, "x": 0},
    "#8f4a1c": {"code": "301", "name": "Mahogany Med", "hex": "#8f4a1c", "R": 143, "G": 74, "B": 28, "h": 56.51, "x": 0},
    "#7f002d": {"code": "304", "name": "Red Medium", "hex": "#7f002d", "R": 127, "G": 0, "B": 45, "h": 14.15, "x": 0},
    "#ffd438": {"code": "307", "name": "Lemon", "hex": "#ffd438", "R": 255, "G": 212, "B": 56, "h": 89.56, "x": 0},
    "#913244": {"code": "309", "name": "Rose Dark", "hex": "#913244", "R": 145, "G": 50, "B": 68, "h": 14.65, "x": 0},
    "#000000": {"code": "310", "name": "Black", "hex": "#000000", "R": 0, "G": 0, "B": 0, "h": null, "x": 0},
    "#003854": {"code": "311", "name": "Wedgewood Ult VyDk", "hex": "#003854", "R": 0, "G": 56, "B": 84, "h": 257.46, "x": 0},
    "#0e4469": {"code": "312", "name": "Baby Blue Very Dark", "hex": "#0e4469", "R": 14, "G": 68, "B": 105, "h": 264.78, "x": 0},
    "#6a3841": {"code": "315", "name": "Antique Mauve Md Dk", "hex": "#6a3841", "R": 106, "G": 56, "B": 65, "h": 10.57, "x": 0},
    "#ac8396": {"code": "316", "name": "Antique Mauve Med", "hex": "#ac8396", "R": 172, "G": 131, "B": 150, "h": 347.87, "x": 0},
    "#4c525a": {"code": "317", "name": "Pewter Gray", "hex": "#4c525a", "R": 76, "G": 82, "B": 90, "h": 264.73, "x": 0},
    "#979aa2": {"code": "318", "name": "Steel Gray Lt", "hex": "#979aa2", "R": 151, "G": 154, "B": 162, "h": 276.49, "x": 0},
    "#173418": {"code": "319", "name": "Pistachio Grn Vy Dk", "hex": "#173418", "R": 23, "G": 52, "B": 24, "h": 141.58, "x": 0},
    "#697f5b": {"code": "320", "name": "Pistachio Green Med", "hex": "#697f5b", "R": 105, "G": 127, "B": 91, "h": 131.62, "x": 0},
    "#7e0018": {"code": "321", "name": "Red", "hex": "#7e0018", "R": 126, "G": 0, "B": 24, "h": 28.21, "x": 0},
    "#577ea2": {"code": "322", "name": "Baby Blue Dark", "hex": "#577ea2", "R": 87, "G": 126, "B": 162, "h": 261.37, "x": 0},
    "#7a002e": {"code": "326", "name": "Rose Very Dark", "hex": "#7a002e", "R": 122, "G": 0, "B": 46, "h": 11.79, "x": 0},
    "#593e63": {"code": "327", "name": "Violet Dark", "hex": "#593e63", "R": 89, "G": 62, "B": 99, "h": 318.17, "x": 0},
    "#3c3e81": {"code": "333", "name": "Blue Violet Very Dark", "hex": "#3c3e81", "R": 60, "G": 62, "B": 129, "h": 296.74, "x": 0},
    "#5b88a4": {"code": "334", "name": "Baby Blue Medium", "hex": "#5b88a4", "R": 91, "G": 136, "B": 164, "h": 248.67, "x": 0},
    "#b75470": {"code": "335", "name": "Rose", "hex": "#b75470", "R": 183, "G": 84, "B": 112, "h": 4.76, "x": 0},
    "#0f2a47": {"code": "336", "name": "Navy Blue", "hex": "#0f2a47", "R": 15, "G": 42, "B": 71, "h": 273.54, "x": 0},
    "#7783b4": {"code": "340", "name": "Blue Violet Medium", "hex": "#7783b4", "R": 119, "G": 131, "B": 180, "h": 285.46, "x": 0},
    "#9cafc9": {"code": "341", "name": "Blue Violet Light", "hex": "#9cafc9", "R": 156, "G": 175, "B": 201, "h": 266.66, "x": 0},
    "#92202a": {"code": "347", "name": "Salmon Very Dark", "hex": "#92202a", "R": 146, "G": 32, "B": 42, "h": 26.71, "x": 0},
    "#950a1e": {"code": "349", "name": "Coral Dark", "hex": "#950a1e", "R": 149, "G": 10, "B": 30, "h": 29.46, "x": 0},
    "#a6322a": {"code": "350", "name": "Coral Medium", "hex": "#a6322a", "R": 166, "G": 50, "B": 42, "h": 34.32, "x": 0},
    "#b7493d": {"code": "351", "name": "Coral", "hex": "#b7493d", "R": 183, "G": 73, "B": 61, "h": 34.38, "x": 0},
    "#d98676": {"code": "352", "name": "Coral Light", "hex": "#d98676", "R": 217, "G": 134, "B": 118, "h": 36.3, "x": 0},
    "#f2c1b0": {"code": "353", "name": "Peach", "hex": "#f2c1b0", "R": 242, "G": 193, "B": 176, "h": 45.27, "x": 0},
    "#6d1b16": {"code": "355", "name": "Terra Cotta Dark", "hex": "#6d1b16", "R": 109, "G": 27, "B": 22, "h": 34.54, "x": 0},
    "#985245": {"code": "356", "name": "Terra Cotta Med", "hex": "#985245", "R": 152, "G": 82, "B": 69, "h": 36.54, "x": 0},
    "#48613e": {"code": "367", "name": "Pistachio Green Dk", "hex": "#48613e", "R": 72, "G": 97, "B": 62, "h": 134.53, "x": 0},
    "#8f9f74": {"code": "368", "name": "Pistachio Green Lt", "hex": "#8f9f74", "R": 143, "G": 159, "B": 116, "h": 123.09, "x": 0},
    "#c2cca8": {"code": "369", "name": "Pistachio Green Vy Lt", "hex": "#c2cca8", "R": 194, "G": 204, "B": 168, "h": 119.91, "x": 0},
    "#9f8852": {"code": "370", "name": "Mustard Medium", "hex": "#9f8852", "R": 159, "G": 136, "B": 82, "h": 87.65, "x": 0},
    "#9e8552": {"code": "371", "name": "Mustard", "hex": "#9e8552", "R": 158, "G": 133, "B": 82, "h": 85.27, "x": 0},
    "#b3a175": {"code": "372", "name": "Mustard Lt", "hex": "#b3a175", "R": 179, "G": 161, "B": 117, "h": 90.09, "x": 0},
    "#662700": {"code": "400", "name": "Mahogany Dark", "hex": "#662700", "R": 102, "G": 39, "B": 0, "h": 53.06, "x": 0},
    "#d39165": {"code": "402", "name": "Mahogany Vy Lt", "hex": "#d39165", "R": 211, "G": 145, "B": 101, "h": 58.87, "x": 0},
    "#ad8272": {"code": "407", "name": "Desert Sand Med", "hex": "#ad8272", "R": 173, "G": 130, "B": 114, "h": 46.56, "x": 0},
    "#3a4143": {"code": "413", "name": "Pewter Gray Dark", "hex": "#3a4143", "R": 58, "G": 65, "B": 67, "h": 222.57, "x": 0},
    "#70747d": {"code": "414", "name": "Steel Gray Dk", "hex": "#70747d", "R": 112, "G": 116, "B": 125, "h": 274.41, "x": 0},
    "#919498": {"code": "415", "name": "Pearl Gray", "hex": "#919498", "R": 145, "G": 148, "B": 152, "h": 263.31, "x": 0},
    "#875a28": {"code": "420", "name": "Hazelnut Brown Dk", "hex": "#875a28", "R": 135, "G": 90, "B": 40, "h": 69.22, "x": 0},
    "#bf9d6d": {"code": "422", "name": "Hazelnut Brown Lt", "hex": "#bf9d6d", "R": 191, "G": 157, "B": 109, "h": 78.41, "x": 0},
    "#5c310d": {"code": "433", "name": "Brown Med", "hex": "#5c310d", "R": 92, "G": 49, "B": 13, "h": 61.07, "x": 0},
    "#794412": {"code": "434", "name": "Brown Light", "hex": "#794412", "R": 121, "G": 68, "B": 18, "h": 63.36, "x": 0},
    "#996232": {"code": "435", "name": "Brown Very Light", "hex": "#996232", "R": 153, "G": 98, "B": 50, "h": 63.86, "x": 0},
    "#ac7948": {"code": "436", "name": "Tan", "hex": "#ac7948", "R": 172, "G": 121, "B": 72, "h": 67.24, "x": 0},
    "#cc9f71": {"code": "437", "name": "Tan Light", "hex": "#cc9f71", "R": 204, "G": 159, "B": 113, "h": 70.42, "x": 0},
    "#f8bd00": {"code": "444", "name": "Lemon Dark", "hex": "#f8bd00", "R": 248, "G": 189, "B": 0, "h": 84.12, "x": 0},
    "#ffed94": {"code": "445", "name": "Lemon Light", "hex": "#ffed94", "R": 255, "G": 237, "B": 148, "h": 97.38, "x": 0},
    "#86746f": {"code": "451", "name": "Shell Gray Dark", "hex": "#86746f", "R": 134, "G": 116, "B": 111, "h": 41.11, "x": 0},
    "#b1a19d": {"code": "452", "name": "Shell Gray Med", "hex": "#b1a19d", "R": 177, "G": 161, "B": 157, "h": 39.27, "x": 0},
    "#ccc1b9": {"code": "453", "name": "Shell Gray Light", "hex": "#ccc1b9", "R": 204, "G": 193, "B": 185, "h": 65.42, "x": 0},
    "#5e5c17": {"code": "469", "name": "Avocado Green", "hex": "#5e5c17", "R": 94, "G": 92, "B": 23, "h": 102.48, "x": 0},
    "#757b23": {"code": "470", "name": "Avocado Grn Lt", "hex": "#757b23", "R": 117, "G": 123, "B": 35, "h": 107.47, "x": 0},
    "#908c48": {"code": "471", "name": "Avocado Grn V Lt", "hex": "#908c48", "R": 144, "G": 140, "B": 72, "h": 102.94, "x": 0},
    "#c1b362": {"code": "472", "name": "Avocado Grn U Lt", "hex": "#c1b362", "R": 193, "G": 179, "B": 98, "h": 97.88, "x": 0},
    "#67001a": {"code": "498", "name": "Red Dark", "hex": "#67001a", "R": 103, "G": 0, "B": 26, "h": 21.83, "x": 0},
    "#132519": {"code": "500", "name": "Blue Green Vy Dk", "hex": "#132519", "R": 19, "G": 37, "B": 25, "h": 152.36, "x": 0},
    "#536a5e": {"code": "501", "name": "Blue Green Dark", "hex": "#536a5e", "R": 83, "G": 106, "B": 94, "h": 160.72, "x": 0},
    "#748c7c": {"code": "502", "name": "Blue Green", "hex": "#748c7c", "R": 116, "G": 140, "B": 124, "h": 154.41, "x": 0},
    "#8ca79a": {"code": "503", "name": "Blue Green Med", "hex": "#8ca79a", "R": 140, "G": 167, "B": 154, "h": 163.27, "x": 0},
    "#c4decc": {"code": "504", "name": "Blue Green Vy Lt", "hex": "#c4decc", "R": 196, "G": 222, "B": 204, "h": 153.76, "x": 0},
    "#37633d": {"code": "505", "name": "Jade Green", "hex": "#37633d", "R": 55, "G": 99, "B": 61, "h": 145.04, "x": 0},
    "#0b5e81": {"code": "517", "name": "Wedgewood Dark", "hex": "#0b5e81", "R": 11, "G": 94, "B": 129, "h": 250.13, "x": 0},
    "#5692a1": {"code": "518", "name": "Wedgewood Light", "hex": "#5692a1", "R": 86, "G": 146, "B": 161, "h": 222.34, "x": 0},
    "#90b7c4": {"code": "519", "name": "Sky Blue", "hex": "#90b7c4", "R": 144, "G": 183, "B": 196, "h": 227.23, "x": 0},
    "#445036": {"code": "520", "name": "Fern Green Dark", "hex": "#445036", "R": 68, "G": 80, "B": 54, "h": 126.3, "x": 0},
    "#969a7e": {"code": "522", "name": "Fern Green", "hex": "#969a7e", "R": 150, "G": 154, "B": 126, "h": 114.44, "x": 0},
    "#a2a286": {"code": "523", "name": "Fern Green Lt", "hex": "#a2a286", "R": 162, "G": 162, "B": 134, "h": 108.54, "x": 0},
    "#b3b094": {"code": "524", "name": "Fern Green Vy Lt", "hex": "#b3b094", "R": 179, "G": 176, "B": 148, "h": 104.17, "x": 0},
    "#484644": {"code": "535", "name": "Ash Gray Vy Lt", "hex": "#484644", "R": 72, "G": 70, "B": 68, "h": 74.82, "x": 0},
    "#dec9b8": {"code": "543", "name": "Beige Brown Ult Vy Lt", "hex": "#dec9b8", "R": 222, "G": 201, "B": 184, "h": 67.56, "x": 0},
    "#350d47": {"code": "550", "name": "Violet Very Dark", "hex": "#350d47", "R": 53, "G": 13, "B": 71, "h": 317.95, "x": 0},
    "#65467f": {"code": "552", "name": "Violet  Medium", "hex": "#65467f", "R": 101, "G": 70, "B": 127, "h": 312.63, "x": 0},
    "#7a5f96": {"code": "553", "name": "Violet", "hex": "#7a5f96", "R": 122, "G": 95, "B": 150, "h": 310.54, "x": 0},
    "#b69ebe": {"code": "554", "name": "Violet Light", "hex": "#b69ebe", "R": 182, "G": 158, "B": 190, "h": 318.01, "x": 0},
    "#315e46": {"code": "561", "name": "Celadon Green VD", "hex": "#315e46", "R": 49, "G": 94, "B": 70, "h": 157.45, "x": 0},
    "#5c865e": {"code": "562", "name": "Jade Medium", "hex": "#5c865e", "R": 92, "G": 134, "B": 94, "h": 143.59, "x": 0},
    "#96b795": {"code": "563", "name": "Jade Light", "hex": "#96b795", "R": 150, "G": 183, "B": 149, "h": 142.54, "x": 0},
    "#aec7a7": {"code": "564", "name": "Jade Very Light", "hex": "#aec7a7", "R": 174, "G": 199, "B": 167, "h": 137.49, "x": 0},
    "#675e17": {"code": "580", "name": "Moss Green Dk", "hex": "#675e17", "R": 103, "G": 94, "B": 23, "h": 97.42, "x": 0},
    "#888420": {"code": "581", "name": "Moss Green", "hex": "#888420", "R": 136, "G": 132, "B": 32, "h": 101.66, "x": 0},
    "#6a9ca1": {"code": "597", "name": "Turquoise", "hex": "#6a9ca1", "R": 106, "G": 156, "B": 161, "h": 207.37, "x": 0},
    "#9abfbb": {"code": "598", "name": "Turquoise Light", "hex": "#9abfbb", "R": 154, "G": 191, "B": 187, "h": 188.98, "x": 0},
    "#931950": {"code": "600", "name": "Cranberry Very Dark", "hex": "#931950", "R": 147, "G": 25, "B": 80, "h": 359.4, "x": 0},
    "#a53061": {"code": "601", "name": "Cranberry Dark", "hex": "#a53061", "R": 165, "G": 48, "B": 97, "h": 358.58, "x": 0},
    "#bb5481": {"code": "602", "name": "Cranberry Medium", "hex": "#bb5481", "R": 187, "G": 84, "B": 129, "h": 353.9, "x": 0},
    "#d284a5": {"code": "603", "name": "Cranberry", "hex": "#d284a5", "R": 210, "G": 132, "B": 165, "h": 352, "x": 0},
    "#e2a6be": {"code": "604", "name": "Cranberry Light", "hex": "#e2a6be", "R": 226, "G": 166, "B": 190, "h": 352.12, "x": 0},
    "#e9bdce": {"code": "605", "name": "Cranberry Very Light", "hex": "#e9bdce", "R": 233, "G": 189, "B": 206, "h": 352.11, "x": 0},
    "#ae0d13": {"code": "606", "name": "Orange?Red Bright", "hex": "#ae0d13", "R": 174, "G": 13, "B": 19, "h": 36.14, "x": 0},
    "#cc3f18": {"code": "608", "name": "Burnt Orange Bright", "hex": "#cc3f18", "R": 204, "G": 63, "B": 24, "h": 43.8, "x": 0},
    "#6b522d": {"code": "610", "name": "Drab Brown Dk", "hex": "#6b522d", "R": 107, "G": 82, "B": 45, "h": 77.74, "x": 0},
    "#705a39": {"code": "611", "name": "Drab Brown", "hex": "#705a39", "R": 112, "G": 90, "B": 57, "h": 79.2, "x": 0},
    "#ad946d": {"code": "612", "name": "Drab Brown Lt", "hex": "#ad946d", "R": 173, "G": 148, "B": 109, "h": 81.46, "x": 0},
    "#d6c9b1": {"code": "613", "name": "Drab Brown V Lt", "hex": "#d6c9b1", "R": 214, "G": 201, "B": 177, "h": 87.46, "x": 0},
    "#704235": {"code": "632", "name": "Desert Sand Ult Vy Dk", "hex": "#704235", "R": 112, "G": 66, "B": 53, "h": 41.65, "x": 0},
    "#7b6f53": {"code": "640", "name": "Beige Gray Vy Dk", "hex": "#7b6f53", "R": 123, "G": 111, "B": 83, "h": 89.76, "x": 0},
    "#92866e": {"code": "642", "name": "Beige Gray Dark", "hex": "#92866e", "R": 146, "G": 134, "B": 110, "h": 88.07, "x": 0},
    "#c8bea8": {"code": "644", "name": "Beige Gray Med", "hex": "#c8bea8", "R": 200, "G": 190, "B": 168, "h": 90.77, "x": 0},
    "#59564c": {"code": "645", "name": "Beaver Gray Vy Dk", "hex": "#59564c", "R": 89, "G": 86, "B": 76, "h": 96.92, "x": 0},
    "#807b69": {"code": "646", "name": "Beaver Gray Dk", "hex": "#807b69", "R": 128, "G": 123, "B": 105, "h": 97.33, "x": 0},
    "#9e9c8a": {"code": "647", "name": "Beaver Gray Med", "hex": "#9e9c8a", "R": 158, "G": 156, "B": 138, "h": 104.51, "x": 0},
    "#b0a89b": {"code": "648", "name": "Beaver Gray Lt", "hex": "#b0a89b", "R": 176, "G": 168, "B": 155, "h": 85.63, "x": 0},
    "#980015": {"code": "666", "name": "Bright Red", "hex": "#980015", "R": 152, "G": 0, "B": 21, "h": 32.88, "x": 0},
    "#dbaf6d": {"code": "676", "name": "Old Gold Lt", "hex": "#dbaf6d", "R": 219, "G": 175, "B": 109, "h": 78.65, "x": 0},
    "#e9d1a0": {"code": "677", "name": "Old Gold Vy Lt", "hex": "#e9d1a0", "R": 233, "G": 209, "B": 160, "h": 87.44, "x": 0},
    "#a0722f": {"code": "680", "name": "Old Gold Dark", "hex": "#a0722f", "R": 160, "G": 114, "B": 47, "h": 75.14, "x": 0},
    "#004f11": {"code": "699", "name": "Green", "hex": "#004f11", "R": 0, "G": 79, "B": 17, "h": 140.64, "x": 0},
    "#286923": {"code": "700", "name": "Green Bright", "hex": "#286923", "R": 40, "G": 105, "B": 35, "h": 137.71, "x": 0},
    "#427829": {"code": "701", "name": "Green Light", "hex": "#427829", "R": 66, "G": 120, "B": 41, "h": 132.13, "x": 0},
    "#5d8738": {"code": "702", "name": "Kelly Green", "hex": "#5d8738", "R": 93, "G": 135, "B": 56, "h": 127.63, "x": 0},
    "#7f9940": {"code": "703", "name": "Chartreuse", "hex": "#7f9940", "R": 127, "G": 153, "B": 64, "h": 118.37, "x": 0},
    "#91a139": {"code": "704", "name": "Chartreuse Bright", "hex": "#91a139", "R": 145, "G": 161, "B": 57, "h": 111.88, "x": 0},
    "#eddfc9": {"code": "712", "name": "Cream", "hex": "#eddfc9", "R": 237, "G": 223, "B": 201, "h": 84.47, "x": 0},
    "#841b61": {"code": "718", "name": "Plum", "hex": "#841b61", "R": 132, "G": 27, "B": 97, "h": 343.12, "x": 0},
    "#b14302": {"code": "720", "name": "Orange Spice Dark", "hex": "#b14302", "R": 177, "G": 67, "B": 2, "h": 51.56, "x": 0},
    "#d1622b": {"code": "721", "name": "Orange Spice Med", "hex": "#d1622b", "R": 209, "G": 98, "B": 43, "h": 50.96, "x": 0},
    "#e78d5b": {"code": "722", "name": "Orange Spice Light", "hex": "#e78d5b", "R": 231, "G": 141, "B": 91, "h": 54.07, "x": 0},
    "#eeaf42": {"code": "725", "name": "Topaz Med Lt", "hex": "#eeaf42", "R": 238, "G": 175, "B": 66, "h": 78.18, "x": 0},
    "#f8c853": {"code": "726", "name": "Topaz Light", "hex": "#f8c853", "R": 248, "G": 200, "B": 83, "h": 85.44, "x": 0},
    "#fcdd87": {"code": "727", "name": "Topaz Vy Lt", "hex": "#fcdd87", "R": 252, "G": 221, "B": 135, "h": 90.3, "x": 0},
    "#e2a538": {"code": "728", "name": "Topaz", "hex": "#e2a538", "R": 226, "G": 165, "B": 56, "h": 78.36, "x": 0},
    "#b88743": {"code": "729", "name": "Old Gold Medium", "hex": "#b88743", "R": 184, "G": 135, "B": 67, "h": 75.06, "x": 0},
    "#5a4808": {"code": "730", "name": "Olive Green V Dk", "hex": "#5a4808", "R": 90, "G": 72, "B": 8, "h": 88.56, "x": 0},
    "#938b37": {"code": "731", "name": "Olive Green Dk", "hex": "#938b37", "R": 147, "G": 139, "B": 55, "h": 100.09, "x": 0},
    "#745c01": {"code": "732", "name": "Olive Green", "hex": "#745c01", "R": 116, "G": 92, "B": 1, "h": 87.95, "x": 0},
    "#a5872c": {"code": "733", "name": "Olive Green Md", "hex": "#a5872c", "R": 165, "G": 135, "B": 44, "h": 88.09, "x": 0},
    "#bb9c4a": {"code": "734", "name": "Olive Green Lt", "hex": "#bb9c4a", "R": 187, "G": 156, "B": 74, "h": 87.79, "x": 0},
    "#d4b48c": {"code": "738", "name": "Tan Very Light", "hex": "#d4b48c", "R": 212, "G": 180, "B": 140, "h": 76.77, "x": 0},
    "#ead4b4": {"code": "739", "name": "Tan Ult Vy Lt", "hex": "#ead4b4", "R": 234, "G": 212, "B": 180, "h": 81.74, "x": 0},
    "#db6400": {"code": "740", "name": "Tangerine", "hex": "#db6400", "R": 219, "G": 100, "B": 0, "h": 56.7, "x": 0},
    "#ee8700": {"code": "741", "name": "Tangerine Med", "hex": "#ee8700", "R": 238, "G": 135, "B": 0, "h": 65.78, "x": 0},
    "#f3a230": {"code": "742", "name": "Tangerine Light", "hex": "#f3a230", "R": 243, "G": 162, "B": 48, "h": 72.32, "x": 0},
    "#f8c058": {"code": "743", "name": "Yellow Med", "hex": "#f8c058", "R": 248, "G": 192, "B": 88, "h": 80.84, "x": 0},
    "#fad182": {"code": "744", "name": "Yellow Pale", "hex": "#fad182", "R": 250, "G": 209, "B": 130, "h": 84.02, "x": 0},
    "#fde2ac": {"code": "745", "name": "Yellow Pale Light", "hex": "#fde2ac", "R": 253, "G": 226, "B": 172, "h": 86.95, "x": 0},
    "#f5ebcf": {"code": "746", "name": "Off White", "hex": "#f5ebcf", "R": 245, "G": 235, "B": 207, "h": 94.46, "x": 0},
    "#cde4e3": {"code": "747", "name": "Peacock Blue Vy Lt", "hex": "#cde4e3", "R": 205, "G": 228, "B": 227, "h": 195.13, "x": 0},
    "#e4b6a0": {"code": "754", "name": "Peach Light", "hex": "#e4b6a0", "R": 228, "G": 182, "B": 160, "h": 52.39, "x": 0},
    "#ce9884": {"code": "758", "name": "Terra Cotta Vy Lt", "hex": "#ce9884", "R": 206, "G": 152, "B": 132, "h": 46.41, "x": 0},
    "#d28c8e": {"code": "760", "name": "Salmon", "hex": "#d28c8e", "R": 210, "G": 140, "B": 142, "h": 20.2, "x": 0},
    "#eab8b9": {"code": "761", "name": "Salmon Light", "hex": "#eab8b9", "R": 234, "G": 184, "B": 185, "h": 19.45, "x": 0},
    "#dddedc": {"code": "762", "name": "Pearl Gray Vy Lt", "hex": "#dddedc", "R": 221, "G": 222, "B": 220, "h": 129.03, "x": 0},
    "#dbdbb2": {"code": "772", "name": "Yellow Green Vy Lt", "hex": "#dbdbb2", "R": 219, "G": 219, "B": 178, "h": 108.37, "x": 0},
    "#c7d6d9": {"code": "775", "name": "Baby Blue Very Light", "hex": "#c7d6d9", "R": 199, "G": 214, "B": 217, "h": 215.79, "x": 0},
    "#fcb0b9": {"code": "776", "name": "Pink Medium", "hex": "#fcb0b9", "R": 252, "G": 176, "B": 185, "h": 12.9, "x": 0},
    "#5f0224": {"code": "777", "name": "Raspberry Very Dark", "hex": "#5f0224", "R": 95, "G": 2, "B": 36, "h": 10.46, "x": 0},
    "#cca8aa": {"code": "778", "name": "Antique Mauve Vy Lt", "hex": "#cca8aa", "R": 204, "G": 168, "B": 170, "h": 16.25, "x": 0},
    "#583c37": {"code": "779", "name": "Cocoa Dark", "hex": "#583c37", "R": 88, "G": 60, "B": 55, "h": 34.73, "x": 0},
    "#844705": {"code": "780", "name": "Topaz Ultra Vy Dk", "hex": "#844705", "R": 132, "G": 71, "B": 5, "h": 63.99, "x": 0},
    "#a26d20": {"code": "781", "name": "Topaz Very Dark", "hex": "#a26d20", "R": 162, "G": 109, "B": 32, "h": 73.43, "x": 0},
    "#995c00": {"code": "782", "name": "Topaz Dark", "hex": "#995c00", "R": 153, "G": 92, "B": 0, "h": 70.05, "x": 0},
    "#c4811c": {"code": "783", "name": "Topaz Medium", "hex": "#c4811c", "R": 196, "G": 129, "B": 28, "h": 72.91, "x": 0},
    "#0c2557": {"code": "791", "name": "Cornflower Blue V D", "hex": "#0c2557", "R": 12, "G": 37, "B": 87, "h": 289.46, "x": 0},
    "#314d82": {"code": "792", "name": "Cornflower Blue Dark", "hex": "#314d82", "R": 49, "G": 77, "B": 130, "h": 282.17, "x": 0},
    "#667c9c": {"code": "793", "name": "Cornflower Blue Med", "hex": "#667c9c", "R": 102, "G": 124, "B": 156, "h": 270.29, "x": 0},
    "#84a0ba": {"code": "794", "name": "Cornflower Blue Light", "hex": "#84a0ba", "R": 132, "G": 160, "B": 186, "h": 257.78, "x": 0},
    "#002a65": {"code": "796", "name": "Royal Blue Dark", "hex": "#002a65", "R": 0, "G": 42, "B": 101, "h": 288.74, "x": 0},
    "#1d4179": {"code": "797", "name": "Royal Blue", "hex": "#1d4179", "R": 29, "G": 65, "B": 121, "h": 282.84, "x": 0},
    "#306598": {"code": "798", "name": "Delft Blue Dark", "hex": "#306598", "R": 48, "G": 101, "B": 152, "h": 269.27, "x": 0},
    "#668caf": {"code": "799", "name": "Delft Blue Medium", "hex": "#668caf", "R": 102, "G": 140, "B": 175, "h": 260.24, "x": 0},
    "#a7c2d5": {"code": "800", "name": "Delft Blue Pale", "hex": "#a7c2d5", "R": 167, "G": 194, "B": 213, "h": 248.26, "x": 0},
    "#4c280f": {"code": "801", "name": "Coffee Brown Dk", "hex": "#4c280f", "R": 76, "G": 40, "B": 15, "h": 57.88, "x": 0},
    "#003558": {"code": "803", "name": "Baby Blue Ult Vy Dk", "hex": "#003558", "R": 0, "G": 53, "B": 88, "h": 267.84, "x": 0},
    "#3d95a5": {"code": "806", "name": "Peacock Blue Dark", "hex": "#3d95a5", "R": 61, "G": 149, "B": 165, "h": 217.52, "x": 0},
    "#55939c": {"code": "807", "name": "Peacock Blue", "hex": "#55939c", "R": 85, "G": 147, "B": 156, "h": 212.11, "x": 0},
    "#88a8c8": {"code": "809", "name": "Delft Blue", "hex": "#88a8c8", "R": 136, "G": 168, "B": 200, "h": 260.49, "x": 0},
    "#7ca3ba": {"code": "813", "name": "Blue Light", "hex": "#7ca3ba", "R": 124, "G": 163, "B": 186, "h": 244.74, "x": 0},
    "#46041b": {"code": "814", "name": "Garnet Dark", "hex": "#46041b", "R": 70, "G": 4, "B": 27, "h": 8.67, "x": 0},
    "#51001c": {"code": "815", "name": "Garnet Medium", "hex": "#51001c", "R": 81, "G": 0, "B": 28, "h": 11.69, "x": 0},
    "#6e001d": {"code": "816", "name": "Garnet", "hex": "#6e001d", "R": 110, "G": 0, "B": 29, "h": 21.36, "x": 0},
    "#8f0115": {"code": "817", "name": "Coral Red Very Dark", "hex": "#8f0115", "R": 143, "G": 1, "B": 21, "h": 32.14, "x": 0},
    "#f7d4d3": {"code": "818", "name": "Baby Pink", "hex": "#f7d4d3", "R": 247, "G": 212, "B": 211, "h": 22.64, "x": 0},
    "#fce6de": {"code": "819", "name": "Baby Pink Light", "hex": "#fce6de", "R": 252, "G": 230, "B": 222, "h": 46.85, "x": 0},
    "#001a53": {"code": "820", "name": "Royal Blue Very Dark", "hex": "#001a53", "R": 0, "G": 26, "B": 83, "h": 294.54, "x": 0},
    "#dfd3bf": {"code": "822", "name": "Beige Gray Light", "hex": "#dfd3bf", "R": 223, "G": 211, "B": 191, "h": 85.82, "x": 0},
    "#090d25": {"code": "823", "name": "Navy Blue Dark", "hex": "#090d25", "R": 9, "G": 13, "B": 37, "h": 289.02, "x": 0},
    "#003c67": {"code": "824", "name": "Blue Very Dark", "hex": "#003c67", "R": 0, "G": 60, "B": 103, "h": 271.69, "x": 0},
    "#06537b": {"code": "825", "name": "Blue Dark", "hex": "#06537b", "R": 6, "G": 83, "B": 123, "h": 259.43, "x": 0},
    "#4e7fa2": {"code": "826", "name": "Blue Medium", "hex": "#4e7fa2", "R": 78, "G": 127, "B": 162, "h": 254.97, "x": 0},
    "#acc8d5": {"code": "827", "name": "Blue Very Light", "hex": "#acc8d5", "R": 172, "G": 200, "B": 213, "h": 235.46, "x": 0},
    "#c3d9d9": {"code": "828", "name": "Sky Blue Vy Lt", "hex": "#c3d9d9", "R": 195, "G": 217, "B": 217, "h": 198.86, "x": 0},
    "#5f3d0d": {"code": "829", "name": "Golden Olive Vy Dk", "hex": "#5f3d0d", "R": 95, "G": 61, "B": 13, "h": 72.53, "x": 0},
    "#674b10": {"code": "830", "name": "Golden Olive Dk", "hex": "#674b10", "R": 103, "G": 75, "B": 16, "h": 80.9, "x": 0},
    "#7f5f16": {"code": "831", "name": "Golden Olive Md", "hex": "#7f5f16", "R": 127, "G": 95, "B": 22, "h": 82.34, "x": 0},
    "#9a731e": {"code": "832", "name": "Golden Olive", "hex": "#9a731e", "R": 154, "G": 115, "B": 30, "h": 81.63, "x": 0},
    "#ae8836": {"code": "833", "name": "Golden Olive Lt", "hex": "#ae8836", "R": 174, "G": 136, "B": 54, "h": 83.2, "x": 0},
    "#c6a357": {"code": "834", "name": "Golden Olive Vy Lt", "hex": "#c6a357", "R": 198, "G": 163, "B": 87, "h": 85.14, "x": 0},
    "#392117": {"code": "838", "name": "Beige Brown Vy Dk", "hex": "#392117", "R": 57, "G": 33, "B": 23, "h": 48.48, "x": 0},
    "#4e3827": {"code": "839", "name": "Beige Brown Dk", "hex": "#4e3827", "R": 78, "G": 56, "B": 39, "h": 63.21, "x": 0},
    "#836a55": {"code": "840", "name": "Beige Brown Med", "hex": "#836a55", "R": 131, "G": 106, "B": 85, "h": 66.65, "x": 0},
    "#a58c79": {"code": "841", "name": "Beige Brown Lt", "hex": "#a58c79", "R": 165, "G": 140, "B": 121, "h": 64.82, "x": 0},
    "#bba592": {"code": "842", "name": "Beige Brown Vy Lt", "hex": "#bba592", "R": 187, "G": 165, "B": 146, "h": 68.81, "x": 0},
    "#403933": {"code": "844", "name": "Beaver Gray Ult Dk", "hex": "#403933", "R": 64, "G": 57, "B": 51, "h": 69.07, "x": 0},
    "#704c1e": {"code": "869", "name": "Hazelnut Brown V Dk", "hex": "#704c1e", "R": 112, "G": 76, "B": 30, "h": 72.21, "x": 0},
    "#0d1d00": {"code": "890", "name": "Pistachio Grn Ult V D", "hex": "#0d1d00", "R": 13, "G": 29, "B": 0, "h": 134.74, "x": 0},
    "#c13955": {"code": "891", "name": "Carnation Dark", "hex": "#c13955", "R": 193, "G": 57, "B": 85, "h": 15.49, "x": 0},
    "#cb4e5d": {"code": "892", "name": "Carnation Medium", "hex": "#cb4e5d", "R": 203, "G": 78, "B": 93, "h": 19.27, "x": 0},
    "#d2637c": {"code": "893", "name": "Carnation Light", "hex": "#d2637c", "R": 210, "G": 99, "B": 124, "h": 8.95, "x": 0},
    "#e898ad": {"code": "894", "name": "Carnation Very Light", "hex": "#e898ad", "R": 232, "G": 152, "B": 173, "h": 2.68, "x": 0},
    "#213a16": {"code": "895", "name": "Hunter Green Vy Dk", "hex": "#213a16", "R": 33, "G": 58, "B": 22, "h": 132.86, "x": 0},
    "#3f2108": {"code": "898", "name": "Coffee Brown Vy Dk", "hex": "#3f2108", "R": 63, "G": 33, "B": 8, "h": 59.92, "x": 0},
    "#c7718a": {"code": "899", "name": "Rose Medium", "hex": "#c7718a", "R": 199, "G": 113, "B": 138, "h": 2.03, "x": 0},
    "#a6361f": {"code": "900", "name": "Burnt Orange Dark", "hex": "#a6361f", "R": 166, "G": 54, "B": 31, "h": 40.77, "x": 0},
    "#540d22": {"code": "902", "name": "Garnet Very Dark", "hex": "#540d22", "R": 84, "G": 13, "B": 34, "h": 10.92, "x": 0},
    "#3e510f": {"code": "904", "name": "Parrot Green V Dk", "hex": "#3e510f", "R": 62, "G": 81, "B": 15, "h": 117.88, "x": 0},
    "#4f6611": {"code": "905", "name": "Parrot Green Dk", "hex": "#4f6611", "R": 79, "G": 102, "B": 17, "h": 117.15, "x": 0},
    "#678700": {"code": "906", "name": "Parrot Green Md", "hex": "#678700", "R": 103, "G": 135, "B": 0, "h": 116.38, "x": 0},
    "#a4ae28": {"code": "907", "name": "Parrot Green Lt", "hex": "#a4ae28", "R": 164, "G": 174, "B": 40, "h": 107.49, "x": 0},
    "#005514": {"code": "909", "name": "Emerald Green Vy Dk", "hex": "#005514", "R": 0, "G": 85, "B": 20, "h": 140.96, "x": 0},
    "#1e6c2b": {"code": "910", "name": "Emerald Green Dark", "hex": "#1e6c2b", "R": 30, "G": 108, "B": 43, "h": 142.5, "x": 0},
    "#398644": {"code": "911", "name": "Emerald Green Med", "hex": "#398644", "R": 57, "G": 134, "B": 68, "h": 143.57, "x": 0},
    "#619b63": {"code": "912", "name": "Emerald Green Lt", "hex": "#619b63", "R": 97, "G": 155, "B": 99, "h": 142.64, "x": 0},
    "#7fad77": {"code": "913", "name": "Nile Green Med", "hex": "#7fad77", "R": 127, "G": 173, "B": 119, "h": 138.59, "x": 0},
    "#56002b": {"code": "915", "name": "Plum Dark", "hex": "#56002b", "R": 86, "G": 0, "B": 43, "h": 358.5, "x": 0},
    "#892867": {"code": "917", "name": "Plum Medium", "hex": "#892867", "R": 137, "G": 40, "B": 103, "h": 342.91, "x": 0},
    "#6c2411": {"code": "918", "name": "Red?Copper Dark", "hex": "#6c2411", "R": 108, "G": 36, "B": 17, "h": 42.83, "x": 0},
    "#711c02": {"code": "919", "name": "Red?Copper", "hex": "#711c02", "R": 113, "G": 28, "B": 2, "h": 44.39, "x": 0},
    "#873113": {"code": "920", "name": "Copper Med", "hex": "#873113", "R": 135, "G": 49, "B": 19, "h": 45.95, "x": 0},
    "#a14a21": {"code": "921", "name": "Copper", "hex": "#a14a21", "R": 161, "G": 74, "B": 33, "h": 50.2, "x": 0},
    "#be673a": {"code": "922", "name": "Copper Light", "hex": "#be673a", "R": 190, "G": 103, "B": 58, "h": 52.21, "x": 0},
    "#1f3f42": {"code": "924", "name": "Gray Green Vy Dark", "hex": "#1f3f42", "R": 31, "G": 63, "B": 66, "h": 206.97, "x": 0},
    "#798985": {"code": "926", "name": "Gray Green Med", "hex": "#798985", "R": 121, "G": 137, "B": 133, "h": 178.49, "x": 0},
    "#9faba3": {"code": "927", "name": "Gray Green Light", "hex": "#9faba3", "R": 159, "G": 171, "B": 163, "h": 155.18, "x": 0},
    "#c8cdc6": {"code": "928", "name": "Gray Green Vy Lt", "hex": "#c8cdc6", "R": 200, "G": 205, "B": 198, "h": 136.04, "x": 0},
    "#2a4551": {"code": "930", "name": "Antique Blue Dark", "hex": "#2a4551", "R": 42, "G": 69, "B": 81, "h": 237.88, "x": 0},
    "#5b7a8e": {"code": "931", "name": "Antique Blue Medium", "hex": "#5b7a8e", "R": 91, "G": 122, "B": 142, "h": 247.92, "x": 0},
    "#8ba1af": {"code": "932", "name": "Antique Blue Light", "hex": "#8ba1af", "R": 139, "G": 161, "B": 175, "h": 245, "x": 0},
    "#272716": {"code": "934", "name": "Avocado Grn Black", "hex": "#272716", "R": 39, "G": 39, "B": 22, "h": 106.88, "x": 0},
    "#33341c": {"code": "935", "name": "Avocado Green Dk", "hex": "#33341c", "R": 51, "G": 52, "B": 28, "h": 108.34, "x": 0},
    "#42421c": {"code": "936", "name": "Avocado Grn V Dk", "hex": "#42421c", "R": 66, "G": 66, "B": 28, "h": 105.39, "x": 0},
    "#515513": {"code": "937", "name": "Avocado Green Md", "hex": "#515513", "R": 81, "G": 85, "B": 19, "h": 106.96, "x": 0},
    "#331806": {"code": "938", "name": "Coffee Brown Ult Dk", "hex": "#331806", "R": 51, "G": 24, "B": 6, "h": 52.54, "x": 0},
    "#0b0c1c": {"code": "939", "name": "Navy Blue Very Dark", "hex": "#0b0c1c", "R": 11, "G": 12, "B": 28, "h": 288.82, "x": 0},
    "#128c64": {"code": "943", "name": "Green Bright Md", "hex": "#128c64", "R": 18, "G": 140, "B": 100, "h": 162.82, "x": 0},
    "#e3c2a7": {"code": "945", "name": "Tawny", "hex": "#e3c2a7", "R": 227, "G": 194, "B": 167, "h": 66.78, "x": 0},
    "#c14b1e": {"code": "946", "name": "Burnt Orange Med", "hex": "#c14b1e", "R": 193, "G": 75, "B": 30, "h": 47.03, "x": 0},
    "#cf5016": {"code": "947", "name": "Burnt Orange", "hex": "#cf5016", "R": 207, "G": 80, "B": 22, "h": 49.08, "x": 0},
    "#edd2bf": {"code": "948", "name": "Peach Very Light", "hex": "#edd2bf", "R": 237, "G": 210, "B": 191, "h": 63.41, "x": 0},
    "#ddbea8": {"code": "950", "name": "Desert Sand Light", "hex": "#ddbea8", "R": 221, "G": 190, "B": 168, "h": 63.18, "x": 0},
    "#eed2b9": {"code": "951", "name": "Tawny Light", "hex": "#eed2b9", "R": 238, "G": 210, "B": 185, "h": 69.63, "x": 0},
    "#9dc493": {"code": "954", "name": "Nile Green", "hex": "#9dc493", "R": 157, "G": 196, "B": 147, "h": 137.42, "x": 0},
    "#bedab1": {"code": "955", "name": "Nile Green Light", "hex": "#bedab1", "R": 190, "G": 218, "B": 177, "h": 134.35, "x": 0},
    "#d35d86": {"code": "956", "name": "Geranium", "hex": "#d35d86", "R": 211, "G": 93, "B": 134, "h": 0.48, "x": 0},
    "#e999b2": {"code": "957", "name": "Geranium Pale", "hex": "#e999b2", "R": 233, "G": 153, "B": 178, "h": 359.07, "x": 0},
    "#66ab92": {"code": "958", "name": "Sea Green Dark", "hex": "#66ab92", "R": 102, "G": 171, "B": 146, "h": 167.66, "x": 0},
    "#75b79f": {"code": "959", "name": "Sea Green Med", "hex": "#75b79f", "R": 117, "G": 183, "B": 159, "h": 168.07, "x": 0},
    "#bf647f": {"code": "961", "name": "Dusty Rose Dark", "hex": "#bf647f", "R": 191, "G": 100, "B": 127, "h": 2.4, "x": 0},
    "#c78093": {"code": "962", "name": "Dusty Rose Medium", "hex": "#c78093", "R": 199, "G": 128, "B": 147, "h": 2.45, "x": 0},
    "#f4cbd0": {"code": "963", "name": "Dusty Rose Ult Vy Lt", "hex": "#f4cbd0", "R": 244, "G": 203, "B": 208, "h": 10.95, "x": 0},
    "#a8d4c7": {"code": "964", "name": "Sea Green Light", "hex": "#a8d4c7", "R": 168, "G": 212, "B": 199, "h": 174.29, "x": 0},
    "#a7bc9c": {"code": "966", "name": "Jade Ultra Vy Lt", "hex": "#a7bc9c", "R": 167, "G": 188, "B": 156, "h": 133.59, "x": 0},
    "#fccab9": {"code": "967", "name": "Apricot Very Light", "hex": "#fccab9", "R": 252, "G": 202, "B": 185, "h": 44.87, "x": 0},
    "#e76e20": {"code": "970", "name": "Pumpkin Light", "hex": "#e76e20", "R": 231, "G": 110, "B": 32, "h": 54.8, "x": 0},
    "#f67f00": {"code": "971", "name": "Pumpkin", "hex": "#f67f00", "R": 246, "G": 127, "B": 0, "h": 61.22, "x": 0},
    "#f59e00": {"code": "972", "name": "Canary Deep", "hex": "#f59e00", "R": 245, "G": 158, "B": 0, "h": 73, "x": 0},
    "#fac300": {"code": "973", "name": "Canary Bright", "hex": "#fac300", "R": 250, "G": 195, "B": 0, "h": 85.75, "x": 0},
    "#652d08": {"code": "975", "name": "Golden Brown Dk", "hex": "#652d08", "R": 101, "G": 45, "B": 8, "h": 55.22, "x": 0},
    "#b56c2a": {"code": "976", "name": "Golden Brown Med", "hex": "#b56c2a", "R": 181, "G": 108, "B": 42, "h": 63.28, "x": 0},
    "#d69254": {"code": "977", "name": "Golden Brown Light", "hex": "#d69254", "R": 214, "G": 146, "B": 84, "h": 65.53, "x": 0},
    "#224214": {"code": "986", "name": "Forest Green Vy Dk", "hex": "#224214", "R": 34, "G": 66, "B": 20, "h": 132.52, "x": 0},
    "#485c2c": {"code": "987", "name": "Forest Green Dk", "hex": "#485c2c", "R": 72, "G": 92, "B": 44, "h": 123.75, "x": 0},
    "#6e7e40": {"code": "988", "name": "Forest Green Med", "hex": "#6e7e40", "R": 110, "G": 126, "B": 64, "h": 117.35, "x": 0},
    "#7f8f50": {"code": "989", "name": "Forest Green", "hex": "#7f8f50", "R": 127, "G": 143, "B": 80, "h": 117.42, "x": 0},
    "#006147": {"code": "991", "name": "Aquamarine Dk", "hex": "#006147", "R": 0, "G": 97, "B": 71, "h": 165.9, "x": 0},
    "#60997d": {"code": "992", "name": "Aquamarine Lt", "hex": "#60997d", "R": 96, "G": 153, "B": 125, "h": 160.59, "x": 0},
    "#81b197": {"code": "993", "name": "Aquamarine Vy Lt", "hex": "#81b197", "R": 129, "G": 177, "B": 151, "h": 159.09, "x": 0},
    "#007baf": {"code": "995", "name": "Electric Blue Dark", "hex": "#007baf", "R": 0, "G": 123, "B": 175, "h": 255.35, "x": 0},
    "#3aabd1": {"code": "996", "name": "Electric Blue Medium", "hex": "#3aabd1", "R": 58, "G": 171, "B": 209, "h": 236.01, "x": 0},
    "#66582f": {"code": "3011", "name": "Khaki Green Dk", "hex": "#66582f", "R": 102, "G": 88, "B": 47, "h": 90.57, "x": 0},
    "#867541": {"code": "3012", "name": "Khaki Green Md", "hex": "#867541", "R": 134, "G": 117, "B": 65, "h": 91.28, "x": 0},
    "#b0a171": {"code": "3013", "name": "Khaki Green Lt", "hex": "#b0a171", "R": 176, "G": 161, "B": 113, "h": 93.72, "x": 0},
    "#2f261a": {"code": "3021", "name": "Brown Gray Vy Dk", "hex": "#2f261a", "R": 47, "G": 38, "B": 26, "h": 77.92, "x": 0},
    "#87846c": {"code": "3022", "name": "Brown Gray Med", "hex": "#87846c", "R": 135, "G": 132, "B": 108, "h": 103.2, "x": 0},
    "#a59d89": {"code": "3023", "name": "Brown Gray Light", "hex": "#a59d89", "R": 165, "G": 157, "B": 137, "h": 92.71, "x": 0},
    "#cac7ba": {"code": "3024", "name": "Brown Gray Vy Lt", "hex": "#cac7ba", "R": 202, "G": 199, "B": 186, "h": 100.26, "x": 0},
    "#392515": {"code": "3031", "name": "Mocha Brown Vy Dk", "hex": "#392515", "R": 57, "G": 37, "B": 21, "h": 63.13, "x": 0},
    "#918064": {"code": "3032", "name": "Mocha Brown Med", "hex": "#918064", "R": 145, "G": 128, "B": 100, "h": 83.47, "x": 0},
    "#d5c6b2": {"code": "3033", "name": "Mocha Brown Vy Lt", "hex": "#d5c6b2", "R": 213, "G": 198, "B": 178, "h": 80.64, "x": 0},
    "#725c6a": {"code": "3041", "name": "Antique Violet Medium", "hex": "#725c6a", "R": 114, "G": 92, "B": 106, "h": 338.36, "x": 0},
    "#a399a5": {"code": "3042", "name": "Antique Violet Light", "hex": "#a399a5", "R": 163, "G": 153, "B": 165, "h": 320.12, "x": 0},
    "#a98352": {"code": "3045", "name": "Yellow Beige Dk", "hex": "#a98352", "R": 169, "G": 131, "B": 82, "h": 75.2, "x": 0},
    "#d0b57e": {"code": "3046", "name": "Yellow Beige Md", "hex": "#d0b57e", "R": 208, "G": 181, "B": 126, "h": 86.32, "x": 0},
    "#e1cea3": {"code": "3047", "name": "Yellow Beige Lt", "hex": "#e1cea3", "R": 225, "G": 206, "B": 163, "h": 89.72, "x": 0},
    "#48451d": {"code": "3051", "name": "Green Gray Dk", "hex": "#48451d", "R": 72, "G": 69, "B": 29, "h": 101.76, "x": 0},
    "#84805a": {"code": "3052", "name": "Green Gray Md", "hex": "#84805a", "R": 132, "G": 128, "B": 90, "h": 102.79, "x": 0},
    "#9d9a70": {"code": "3053", "name": "Green Gray", "hex": "#9d9a70", "R": 157, "G": 154, "B": 112, "h": 104.48, "x": 0},
    "#ab7a63": {"code": "3064", "name": "Desert Sand", "hex": "#ab7a63", "R": 171, "G": 122, "B": 99, "h": 51.33, "x": 0},
    "#d0d0c4": {"code": "3072", "name": "Beaver Gray Vy Lt", "hex": "#d0d0c4", "R": 208, "G": 208, "B": 196, "h": 109.62, "x": 0},
    "#fae3a0": {"code": "3078", "name": "Golden Yellow Vy Lt", "hex": "#fae3a0", "R": 250, "G": 227, "B": 160, "h": 92.37, "x": 0},
    "#a1bdcd": {"code": "3325", "name": "Baby Blue Light", "hex": "#a1bdcd", "R": 161, "G": 189, "B": 205, "h": 241.89, "x": 0},
    "#e3a5af": {"code": "3326", "name": "Rose Light", "hex": "#e3a5af", "R": 227, "G": 165, "B": 175, "h": 9.19, "x": 0},
    "#a74a4a": {"code": "3328", "name": "Salmon Dark", "hex": "#a74a4a", "R": 167, "G": 74, "B": 74, "h": 26.21, "x": 0},
    "#df6c49": {"code": "3340", "name": "Apricot Med", "hex": "#df6c49", "R": 223, "G": 108, "B": 73, "h": 43.57, "x": 0},
    "#ea8e77": {"code": "3341", "name": "Apricot", "hex": "#ea8e77", "R": 234, "G": 142, "B": 119, "h": 39.84, "x": 0},
    "#2b3a06": {"code": "3345", "name": "Hunter Green Dk", "hex": "#2b3a06", "R": 43, "G": 58, "B": 6, "h": 118.09, "x": 0},
    "#5a612b": {"code": "3346", "name": "Hunter Green", "hex": "#5a612b", "R": 90, "G": 97, "B": 43, "h": 111.37, "x": 0},
    "#73793b": {"code": "3347", "name": "Yellow Green Med", "hex": "#73793b", "R": 115, "G": 121, "B": 59, "h": 110.05, "x": 0},
    "#bdb473": {"code": "3348", "name": "Yellow Green Lt", "hex": "#bdb473", "R": 189, "G": 180, "B": 115, "h": 100.49, "x": 0},
    "#882c4a": {"code": "3350", "name": "Dusty Rose Ultra Dark", "hex": "#882c4a", "R": 136, "G": 44, "B": 74, "h": 4.49, "x": 0},
    "#d79fac": {"code": "3354", "name": "Dusty Rose Light", "hex": "#d79fac", "R": 215, "G": 159, "B": 172, "h": 3.67, "x": 0},
    "#525a3b": {"code": "3362", "name": "Pine Green Dk", "hex": "#525a3b", "R": 82, "G": 90, "B": 59, "h": 118.24, "x": 0},
    "#6c714d": {"code": "3363", "name": "Pine Green Md", "hex": "#6c714d", "R": 108, "G": 113, "B": 77, "h": 113.32, "x": 0},
    "#878759": {"code": "3364", "name": "Pine Green", "hex": "#878759", "R": 135, "G": 135, "B": 89, "h": 107, "x": 0},
    "#1f0500": {"code": "3371", "name": "Black Brown", "hex": "#1f0500", "R": 31, "G": 5, "B": 0, "h": 29.7, "x": 0},
    "#9c4c8d": {"code": "3607", "name": "Plum Light", "hex": "#9c4c8d", "R": 156, "G": 76, "B": 141, "h": 333.39, "x": 0},
    "#c994b7": {"code": "3608", "name": "Plum Very Light", "hex": "#c994b7", "R": 201, "G": 148, "B": 183, "h": 337.91, "x": 0},
    "#ddb5d2": {"code": "3609", "name": "Plum Ultra Light", "hex": "#ddb5d2", "R": 221, "G": 181, "B": 210, "h": 334.53, "x": 0},
    "#530d2b": {"code": "3685", "name": "Mauve Very Dark", "hex": "#530d2b", "R": 83, "G": 13, "B": 43, "h": 359.94, "x": 0},
    "#a25a76": {"code": "3687", "name": "Mauve", "hex": "#a25a76", "R": 162, "G": 90, "B": 118, "h": 355.22, "x": 0},
    "#ba8198": {"code": "3688", "name": "Mauve Medium", "hex": "#ba8198", "R": 186, "G": 129, "B": 152, "h": 352.36, "x": 0},
    "#e7bfcd": {"code": "3689", "name": "Mauve Light", "hex": "#e7bfcd", "R": 231, "G": 191, "B": 205, "h": 354.31, "x": 0},
    "#c14450": {"code": "3705", "name": "Melon Dark", "hex": "#c14450", "R": 193, "G": 68, "B": 80, "h": 21.97, "x": 0},
    "#e07b8d": {"code": "3706", "name": "Melon Medium", "hex": "#e07b8d", "R": 224, "G": 123, "B": 141, "h": 10.87, "x": 0},
    "#ea9baf": {"code": "3708", "name": "Melon Light", "hex": "#ea9baf", "R": 234, "G": 155, "B": 175, "h": 3.26, "x": 0},
    "#bd6868": {"code": "3712", "name": "Salmon Medium", "hex": "#bd6868", "R": 189, "G": 104, "B": 104, "h": 24.11, "x": 0},
    "#f3cecd": {"code": "3713", "name": "Salmon Very Light", "hex": "#f3cecd", "R": 243, "G": 206, "B": 205, "h": 22.6, "x": 0},
    "#e4aaba": {"code": "3716", "name": "Dusty Rose Med Vy Lt", "hex": "#e4aaba", "R": 228, "G": 170, "B": 186, "h": 0.41, "x": 0},
    "#793537": {"code": "3721", "name": "Shell Pink Dark", "hex": "#793537", "R": 121, "G": 53, "B": 55, "h": 23.87, "x": 0},
    "#8c4e52": {"code": "3722", "name": "Shell Pink Med", "hex": "#8c4e52", "R": 140, "G": 78, "B": 82, "h": 19.04, "x": 0},
    "#87565e": {"code": "3726", "name": "Antique Mauve Dark", "hex": "#87565e", "R": 135, "G": 86, "B": 94, "h": 10.17, "x": 0},
    "#c49ea9": {"code": "3727", "name": "Antique Mauve Light", "hex": "#c49ea9", "R": 196, "G": 158, "B": 169, "h": 358.67, "x": 0},
    "#aa4e6b": {"code": "3731", "name": "Dusty Rose Very Dark", "hex": "#aa4e6b", "R": 170, "G": 78, "B": 107, "h": 2.28, "x": 0},
    "#c17a8d": {"code": "3733", "name": "Dusty Rose", "hex": "#c17a8d", "R": 193, "G": 122, "B": 141, "h": 2.61, "x": 0},
    "#553c48": {"code": "3740", "name": "Antique Violet Dark", "hex": "#553c48", "R": 85, "G": 60, "B": 72, "h": 347.27, "x": 0},
    "#c3bcc4": {"code": "3743", "name": "Antique Violet Vy Lt", "hex": "#c3bcc4", "R": 195, "G": 188, "B": 196, "h": 321.2, "x": 0},
    "#555f9a": {"code": "3746", "name": "Blue Violet Dark", "hex": "#555f9a", "R": 85, "G": 95, "B": 154, "h": 290.2, "x": 0},
    "#c8ceda": {"code": "3747", "name": "Blue Violet Vy Lt", "hex": "#c8ceda", "R": 200, "G": 206, "B": 218, "h": 272.19, "x": 0},
    "#0d3346": {"code": "3750", "name": "Antique Blue Very Dk", "hex": "#0d3346", "R": 13, "G": 51, "B": 70, "h": 249.85, "x": 0},
    "#adbfc7": {"code": "3752", "name": "Antique Blue Very Lt", "hex": "#adbfc7", "R": 173, "G": 191, "B": 199, "h": 233.58, "x": 0},
    "#c7d3d7": {"code": "3753", "name": "Antique Blue Ult Vy Lt", "hex": "#c7d3d7", "R": 199, "G": 211, "B": 215, "h": 225.79, "x": 0},
    "#86abc2": {"code": "3755", "name": "Baby Blue", "hex": "#86abc2", "R": 134, "G": 171, "B": 194, "h": 245.97, "x": 0},
    "#e5e8e2": {"code": "3756", "name": "Baby Blue Ult Vy Lt", "hex": "#e5e8e2", "R": 229, "G": 232, "B": 226, "h": 128.96, "x": 0},
    "#428198": {"code": "3760", "name": "Wedgewood Med", "hex": "#428198", "R": 66, "G": 129, "B": 152, "h": 234.17, "x": 0},
    "#b1d1d5": {"code": "3761", "name": "Sky Blue Light", "hex": "#b1d1d5", "R": 177, "G": 209, "B": 213, "h": 209.58, "x": 0},
    "#006079": {"code": "3765", "name": "Peacock Blue Vy Dk", "hex": "#006079", "R": 0, "G": 96, "B": 121, "h": 235.38, "x": 0},
    "#90bec1": {"code": "3766", "name": "Peacock Blue Light", "hex": "#90bec1", "R": 144, "G": 190, "B": 193, "h": 204.14, "x": 0},
    "#495a5f": {"code": "3768", "name": "Gray Green Dark", "hex": "#495a5f", "R": 73, "G": 90, "B": 95, "h": 223.8, "x": 0},
    "#f8e6d5": {"code": "3770", "name": "Tawny Vy Light", "hex": "#f8e6d5", "R": 248, "G": 230, "B": 213, "h": 72.14, "x": 0},
    "#d6a184": {"code": "3771", "name": "Terra Cotta Ult Vy Lt", "hex": "#d6a184", "R": 214, "G": 161, "B": 132, "h": 55.13, "x": 0},
    "#8a5a4a": {"code": "3772", "name": "Desert Sand Vy Dk", "hex": "#8a5a4a", "R": 138, "G": 90, "B": 74, "h": 44.29, "x": 0},
    "#b67552": {"code": "3773", "name": "Desert Sand Dark", "hex": "#b67552", "R": 182, "G": 117, "B": 82, "h": 53.75, "x": 0},
    "#e5c9b6": {"code": "3774", "name": "Desert Sand Vy Lt", "hex": "#e5c9b6", "R": 229, "G": 201, "B": 182, "h": 62.3, "x": 0},
    "#b16434": {"code": "3776", "name": "Mahogany Light", "hex": "#b16434", "R": 177, "G": 100, "B": 52, "h": 55.91, "x": 0},
    "#5f0d11": {"code": "3777", "name": "Terra Cotta Vy Dk", "hex": "#5f0d11", "R": 95, "G": 13, "B": 17, "h": 30.89, "x": 0},
    "#b16e5d": {"code": "3778", "name": "Terra Cotta Light", "hex": "#b16e5d", "R": 177, "G": 110, "B": 93, "h": 39.93, "x": 0},
    "#dead9e": {"code": "3779", "name": "Rosewood Ult Vy Lt", "hex": "#dead9e", "R": 222, "G": 173, "B": 158, "h": 42.77, "x": 0},
    "#483420": {"code": "3781", "name": "Mocha Brown Dk", "hex": "#483420", "R": 72, "G": 52, "B": 32, "h": 69.21, "x": 0},
    "#b4a38a": {"code": "3782", "name": "Mocha Brown Lt", "hex": "#b4a38a", "R": 180, "G": 163, "B": 138, "h": 82, "x": 0},
    "#4e4836": {"code": "3787", "name": "Brown Gray Dark", "hex": "#4e4836", "R": 78, "G": 72, "B": 54, "h": 93.86, "x": 0},
    "#7b644d": {"code": "3790", "name": "Beige Gray Ult Dk", "hex": "#7b644d", "R": 123, "G": 100, "B": 77, "h": 70.97, "x": 0},
    "#2c2c2a": {"code": "3799", "name": "Pewter Gray Vy Dk", "hex": "#2c2c2a", "R": 44, "G": 44, "B": 42, "h": 109.82, "x": 0},
    "#b62e3b": {"code": "3801", "name": "Melon Very Dark", "hex": "#b62e3b", "R": 182, "G": 46, "B": 59, "h": 25.37, "x": 0},
    "#552331": {"code": "3802", "name": "Antique Mauve Vy Dk", "hex": "#552331", "R": 85, "G": 35, "B": 49, "h": 5.03, "x": 0},
    "#6c2a47": {"code": "3803", "name": "Mauve Dark", "hex": "#6c2a47", "R": 108, "G": 42, "B": 71, "h": 354.31, "x": 0},
    "#a73970": {"code": "3804", "name": "Cyclamen Pink Dark", "hex": "#a73970", "R": 167, "G": 57, "B": 112, "h": 351.61, "x": 0},
    "#b0497e": {"code": "3805", "name": "Cyclamen Pink", "hex": "#b0497e", "R": 176, "G": 73, "B": 126, "h": 349.37, "x": 0},
    "#d48cac": {"code": "3806", "name": "Cyclamen Pink Light", "hex": "#d48cac", "R": 212, "G": 140, "B": 172, "h": 350.23, "x": 0},
    "#495d89": {"code": "3807", "name": "Cornflower Blue", "hex": "#495d89", "R": 73, "G": 93, "B": 137, "h": 280.59, "x": 0},
    "#194e50": {"code": "3808", "name": "Turquoise Ult Vy Dk", "hex": "#194e50", "R": 25, "G": 78, "B": 80, "h": 201.13, "x": 0},
    "#016163": {"code": "3809", "name": "Turquoise Vy Dark", "hex": "#016163", "R": 1, "G": 97, "B": 99, "h": 199.41, "x": 0},
    "#468588": {"code": "3810", "name": "Turquoise Dark", "hex": "#468588", "R": 70, "G": 133, "B": 136, "h": 202.19, "x": 0},
    "#b6d2ce": {"code": "3811", "name": "Turquoise Very Light", "hex": "#b6d2ce", "R": 182, "G": 210, "B": 206, "h": 186.59, "x": 0},
    "#008867": {"code": "3812", "name": "Sea Green Vy Dk", "hex": "#008867", "R": 0, "G": 136, "B": 103, "h": 167.29, "x": 0},
    "#b3c4b6": {"code": "3813", "name": "Blue Green Lt", "hex": "#b3c4b6", "R": 179, "G": 196, "B": 182, "h": 149.17, "x": 0},
    "#2c7862": {"code": "3814", "name": "Aquamarine", "hex": "#2c7862", "R": 44, "G": 120, "B": 98, "h": 169.82, "x": 0},
    "#4c7257": {"code": "3815", "name": "Celadon Green Dk", "hex": "#4c7257", "R": 76, "G": 114, "B": 87, "h": 151.28, "x": 0},
    "#769a85": {"code": "3816", "name": "Celadon Green", "hex": "#769a85", "R": 118, "G": 154, "B": 133, "h": 157.5, "x": 0},
    "#a5bba8": {"code": "3817", "name": "Celadon Green Lt", "hex": "#a5bba8", "R": 165, "G": 187, "B": 168, "h": 147.66, "x": 0},
    "#004015": {"code": "3818", "name": "Emerald Grn Ult V Dk", "hex": "#004015", "R": 0, "G": 64, "B": 21, "h": 144.94, "x": 0},
    "#d5c25a": {"code": "3819", "name": "Moss Green Lt", "hex": "#d5c25a", "R": 213, "G": 194, "B": 90, "h": 96.43, "x": 0},
    "#d09a2e": {"code": "3820", "name": "Straw Dark", "hex": "#d09a2e", "R": 208, "G": 154, "B": 46, "h": 80.18, "x": 0},
    "#ddab46": {"code": "3821", "name": "Straw", "hex": "#ddab46", "R": 221, "G": 171, "B": 70, "h": 81.92, "x": 0},
    "#ecc46f": {"code": "3822", "name": "Straw Light", "hex": "#ecc46f", "R": 236, "G": 196, "B": 111, "h": 85.12, "x": 0},
    "#fbeac5": {"code": "3823", "name": "Yellow Ultra Pale", "hex": "#fbeac5", "R": 251, "G": 234, "B": 197, "h": 89.85, "x": 0},
    "#f6aa97": {"code": "3824", "name": "Apricot Light", "hex": "#f6aa97", "R": 246, "G": 170, "B": 151, "h": 39.42, "x": 0},
    "#f2a87e": {"code": "3825", "name": "Pumpkin Pale", "hex": "#f2a87e", "R": 242, "G": 168, "B": 126, "h": 55.33, "x": 0},
    "#975423": {"code": "3826", "name": "Golden Brown", "hex": "#975423", "R": 151, "G": 84, "B": 35, "h": 58.78, "x": 0},
    "#dea369": {"code": "3827", "name": "Golden Brown Pale", "hex": "#dea369", "R": 222, "G": 163, "B": 105, "h": 68.37, "x": 0},
    "#a8824f": {"code": "3828", "name": "Hazelnut Brown", "hex": "#a8824f", "R": 168, "G": 130, "B": 79, "h": 75.91, "x": 0},
    "#946219": {"code": "3829", "name": "Old Gold Vy Dark", "hex": "#946219", "R": 148, "G": 98, "B": 25, "h": 73.11, "x": 0},
    "#87362b": {"code": "3830", "name": "Terra Cotta", "hex": "#87362b", "R": 135, "G": 54, "B": 43, "h": 35.61, "x": 0},
    "#90253b": {"code": "3831", "name": "Raspberry Dark", "hex": "#90253b", "R": 144, "G": 37, "B": 59, "h": 16.3, "x": 0},
    "#b1475d": {"code": "3832", "name": "Raspberry Medium", "hex": "#b1475d", "R": 177, "G": 71, "B": 93, "h": 11.92, "x": 0},
    "#c26f80": {"code": "3833", "name": "Raspberry Light", "hex": "#c26f80", "R": 194, "G": 111, "B": 128, "h": 8.33, "x": 0},
    "#4a2748": {"code": "3834", "name": "Grape Dark", "hex": "#4a2748", "R": 74, "G": 39, "B": 72, "h": 328.38, "x": 0},
    "#775779": {"code": "3835", "name": "Grape Medium", "hex": "#775779", "R": 119, "G": 87, "B": 121, "h": 324.22, "x": 0},
    "#a38aa4": {"code": "3836", "name": "Grape Light", "hex": "#a38aa4", "R": 163, "G": 138, "B": 164, "h": 324.23, "x": 0},
    "#543a82": {"code": "3837", "name": "Lavender Ultra Dark", "hex": "#543a82", "R": 84, "G": 58, "B": 130, "h": 307.65, "x": 0},
    "#5b79aa": {"code": "3838", "name": "Lavender Blue Dark", "hex": "#5b79aa", "R": 91, "G": 121, "B": 170, "h": 275.5, "x": 0},
    "#7490bb": {"code": "3839", "name": "Lavender Blue Med", "hex": "#7490bb", "R": 116, "G": 144, "B": 187, "h": 272.29, "x": 0},
    "#a7c1d9": {"code": "3840", "name": "Lavender Blue Light", "hex": "#a7c1d9", "R": 167, "G": 193, "B": 217, "h": 256.55, "x": 0},
    "#bfd2d9": {"code": "3841", "name": "Baby Blue Pale", "hex": "#bfd2d9", "R": 191, "G": 210, "B": 217, "h": 228.53, "x": 0},
    "#004868": {"code": "3842", "name": "Wedgewood Vry Dk", "hex": "#004868", "R": 0, "G": 72, "B": 104, "h": 254.54, "x": 0},
    "#0088b7": {"code": "3843", "name": "Electric Blue", "hex": "#0088b7", "R": 0, "G": 136, "B": 183, "h": 247.6, "x": 0},
    "#0088a1": {"code": "3844", "name": "Turquoise Bright Dark", "hex": "#0088a1", "R": 0, "G": 136, "B": 161, "h": 225.44, "x": 0},
    "#00abbd": {"code": "3845", "name": "Turquoise Bright Med", "hex": "#00abbd", "R": 0, "G": 171, "B": 189, "h": 213.26, "x": 0},
    "#38c4d3": {"code": "3846", "name": "Turquoise Bright Light", "hex": "#38c4d3", "R": 56, "G": 196, "B": 211, "h": 210.05, "x": 0},
    "#005245": {"code": "3847", "name": "Teal Green Dark", "hex": "#005245", "R": 0, "G": 82, "B": 69, "h": 176.72, "x": 0},
    "#2f796c": {"code": "3848", "name": "Teal Green Med", "hex": "#2f796c", "R": 47, "G": 121, "B": 108, "h": 179.34, "x": 0},
    "#669e94": {"code": "3849", "name": "Teal Green Light", "hex": "#669e94", "R": 102, "G": 158, "B": 148, "h": 181.7, "x": 0},
    "#007c4d": {"code": "3850", "name": "Green Bright Dk", "hex": "#007c4d", "R": 0, "G": 124, "B": 77, "h": 156.97, "x": 0},
    "#59a984": {"code": "3851", "name": "Green Bright Lt", "hex": "#59a984", "R": 89, "G": 169, "B": 132, "h": 160.83, "x": 0},
    "#c08103": {"code": "3852", "name": "Straw Very Dark", "hex": "#c08103", "R": 192, "G": 129, "B": 3, "h": 75.92, "x": 0},
    "#cd722d": {"code": "3853", "name": "Autumn Gold Dk", "hex": "#cd722d", "R": 205, "G": 114, "B": 45, "h": 59.18, "x": 0},
    "#e29a5d": {"code": "3854", "name": "Autumn Gold Med", "hex": "#e29a5d", "R": 226, "G": 154, "B": 93, "h": 63.96, "x": 0},
    "#f9cb8c": {"code": "3855", "name": "Autumn Gold Lt", "hex": "#f9cb8c", "R": 249, "G": 203, "B": 140, "h": 77.5, "x": 0},
    "#e9bb93": {"code": "3856", "name": "Mahogany Ult Vy Lt", "hex": "#e9bb93", "R": 233, "G": 187, "B": 147, "h": 67.18, "x": 0},
    "#441508": {"code": "3857", "name": "Rosewood Dark", "hex": "#441508", "R": 68, "G": 21, "B": 8, "h": 40.22, "x": 0},
    "#632f2b": {"code": "3858", "name": "Rosewood Med", "hex": "#632f2b", "R": 99, "G": 47, "B": 43, "h": 30.26, "x": 0},
    "#a16f5d": {"code": "3859", "name": "Rosewood Light", "hex": "#a16f5d", "R": 161, "G": 111, "B": 93, "h": 45.75, "x": 0},
    "#6c514b": {"code": "3860", "name": "Cocoa", "hex": "#6c514b", "R": 108, "G": 81, "B": 75, "h": 37.41, "x": 0},
    "#9b837e": {"code": "3861", "name": "Cocoa Light", "hex": "#9b837e", "R": 155, "G": 131, "B": 126, "h": 36.3, "x": 0},
    "#7c583b": {"code": "3862", "name": "Mocha Beige Dark", "hex": "#7c583b", "R": 124, "G": 88, "B": 59, "h": 63.72, "x": 0},
    "#937055": {"code": "3863", "name": "Mocha Beige Med", "hex": "#937055", "R": 147, "G": 112, "B": 85, "h": 63.61, "x": 0},
    "#bfa28b": {"code": "3864", "name": "Mocha Beige Light", "hex": "#bfa28b", "R": 191, "G": 162, "B": 139, "h": 65.88, "x": 0},
    "#fbf9f0": {"code": "3865", "name": "Winter White", "hex": "#fbf9f0", "R": 251, "G": 249, "B": 240, "h": 100.98, "x": 0},
    "#e5dbcf": {"code": "3866", "name": "Mocha Brn Ult Vy Lt", "hex": "#e5dbcf", "R": 229, "G": 219, "B": 207, "h": 78.96, "x": 0},
    "#ffffff": {"code": "B5200", "name": "Snow White", "hex": "#ffffff", "R": 255, "G": 255, "B": 255, "h": null, "x": 0},
    "#f0e3cd": {"code": "Ecru", "name": "Ecru", "hex": "#f0e3cd", "R": 240, "G": 227, "B": 205, "h": 86.09, "x": 0},
    "#fcfbf8": {"code": "White", "name": "White", "hex": "#fcfbf8", "R": 252, "G": 251, "B": 248, "h": 97.04, "x": 0},
    "#f9f9f5": {"code": "Blanc", "name": "Blanc", "hex": "#f9f9f5", "R": 249, "G": 249, "B": 245, "h": 110.02, "x": 0}
};
export default DMC;
