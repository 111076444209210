// noinspection JSCheckFunctionSignatures

import {Accordion, AccordionDetails, AccordionGroup, AccordionSummary, ModalClose, Textarea} from "@mui/joy";
import React, {useState} from 'react';
import PaletteRoundedIcon from "@mui/icons-material/PaletteRounded";
import FormatColorResetRoundedIcon from '@mui/icons-material/FormatColorResetRounded';
import ColorizeRoundedIcon from '@mui/icons-material/ColorizeRounded';
import GradientRoundedIcon from '@mui/icons-material/GradientRounded';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';

import {
    calculate,
    findSingles,
    fixSingles,
    dropBlends,
    dropPalette,
    setMinBlends,
    setMinCrosses,
    setUseBlends,
    setShowSingles,
    setRadius,
    setNearestColors,
    exportPattern,
    setSingles,
    setSelectedColor,
    setName,
    setCustomPalette
} from "../reducers/patternSlice";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/joy/Typography";
import {InfoOutlined} from "@mui/icons-material";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../utils/theme';
import {useTranslation} from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ThreadCount from "./ThreadCount";
import { Link } from "react-router-dom";

function Settings() {
    const [index, setIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const [tcOpen, setTcOpen] = useState(false);
    const [custom, setCustom] = useState('');
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const {progress, rendered, useBlends, showSingles, nearestColors, radius, name} = useSelector((state) => state.pattern);
    const {minCrossesPercentage, minBlendsPercentage} = useSelector((state) => state.pattern);

    const handleMinCrossesChange = (event, newValue) => {
        if (newValue < minCrossesPercentage) {
            dispatch(dropPalette());
        }
        dispatch(dropBlends());
        dispatch(setUseBlends(false));
        dispatch(setMinCrosses(newValue));
        dispatch(setSingles([]));
    }

    const handleMinBlendsChange = (event, newValue) => {
        if (newValue < minBlendsPercentage) {
            dispatch(dropBlends());
            dispatch(setUseBlends(true));
        }
        dispatch(setMinBlends(newValue));
    }

    const handleUseBlends = (newValue) => {
        if (!newValue) {
            dispatch(dropBlends());
            dispatch(setUseBlends(false));
        }
        else {
            dispatch(setUseBlends(true));
        }
    }

    const handleShowSingles = (newValue) => {
        dispatch(setShowSingles(newValue));
    }

    const handleRadiusChange = (event, newValue) => {
       dispatch(setRadius(newValue));
       dispatch(setSingles([]));
    }

    const handleNearestColorsChange = (event, newValue) => {
        dispatch(setNearestColors(newValue));
        dispatch(setSingles([]));
    }

    return (
        <>
            <ThreadCount open={tcOpen} setOpen={setTcOpen} />
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog color="neutral" size="lg" variant="soft" sx={{width:'500px', maxWidth:'100%'}}>
                    <ModalClose />
                    <DialogTitle>{t('set.customp.title')}</DialogTitle>
                    <Typography variant="body1">{t('set.customp.text')}</Typography>
                    <Textarea
                        value={custom}
                        onChange={(e) => setCustom(e.target.value)}
                        minRows={3}
                        size="lg"
                        variant="outlined"
                    />
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ThemeProvider theme={theme}>
                            <Button
                                onClick={() => {setOpen(false);}}
                                color="primary"
                                variant="contained"
                                size="small"
                                sx={{flex:1}}
                                style={{width: '100%'}}
                            >
                                {t('set.customp.cancel')}
                            </Button>
                            <Button
                                startIcon={<ColorizeRoundedIcon/>}
                                onClick={() => {dispatch(setCustomPalette(custom)); setOpen(false);}}
                                color="secondary"
                                variant="contained"
                                size="small"
                                sx={{flex:1}}
                                style={{width: '100%'}}
                            >
                                {t('set.customp.btn')}
                            </Button>
                        </ThemeProvider>
                    </Stack>
                </ModalDialog>
            </Modal>
        <AccordionGroup>
            <Accordion
                expanded={index === 0}
                onChange={(event, expanded) => {
                    setIndex(expanded ? 0 : null);
                }}
            >
                <AccordionSummary>{t('set.p1.title')}</AccordionSummary>
                <AccordionDetails>
                    <Card variant="outlined" sx={{p:2}}>
                    <Typography startDecorator={<InfoOutlined />} level="body-sm" mb={2}>{t('set.p1.t1')}</Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <ThemeProvider theme={theme}>
                            <Button
                                startIcon={<FormatColorResetRoundedIcon/>}
                                onClick={() => {setIndex(1); dispatch(dropPalette());}}
                                color="primary"
                                variant="contained"
                                size="small"
                                disabled={progress}
                                sx={{flex:1}}
                                style={{width: '100%'}}
                            >
                                {t('set.reset')}
                            </Button>
                            </ThemeProvider>
                            <Typography align="center" level="body-sm">OR</Typography>
                            <ThemeProvider theme={theme}>
                            <Button
                                startIcon={<ColorizeRoundedIcon/>}
                                onClick={() => {setOpen(true);setIndex(1);}}
                                color="secondary"
                                variant="contained"
                                size="small"
                                sx={{flex:1}}
                                style={{width: '100%'}}
                            >
                                {t('set.custom')}
                            </Button>
                            </ThemeProvider>
                        </Stack>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={index === 1}
                onChange={(event, expanded) => {
                    setIndex(expanded ? 1 : null);
                }}
            >
                <AccordionSummary>{t('set.p2.title')}</AccordionSummary>
                <AccordionDetails>
                    <Card variant="outlined" sx={{p:2}}>
                        <Typography level="body-sm" mb={2}>{t('set.p2.t1')}</Typography>
                        <Typography level="body-sm" mb={2}>{t('set.p2.t2')}</Typography>
                        <Typography startDecorator={<InfoOutlined />} level="body-sm" mb={2}>{t('set.p2.t3')}</Typography>
                        <Row className="g-2">
                            <Col xs={8}><Typography variant="body1">{t('set.p2.mincross')}</Typography></Col>
                            <Col xs={4}><Typography variant="body1">{minCrossesPercentage}%</Typography></Col>
                            <Col>
                                <ThemeProvider theme={theme}>
                                    <Slider aria-label="minCrossesPercentage"
                                        value={minCrossesPercentage} marks
                                        step={0.05} min={0} max={1}
                                        valueLabelDisplay="auto"
                                        onChange={handleMinCrossesChange}
                                        disabled={progress}
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row>
                        <div align="center">
                            <ThemeProvider theme={theme}>
                            <Button
                                startIcon={<PaletteRoundedIcon/>}
                                onClick={() => {dispatch(calculate()); setIndex(2);}}
                                disabled={progress}
                                color="secondary"
                                variant="contained"
                                size="small"
                                style={{width: '100%'}}
                            >
                                {t('set.calc')}
                            </Button>
                            </ThemeProvider>
                        </div>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={index === 2}
                onChange={(event, expanded) => {
                    setIndex(expanded ? 2 : null);
                }}
            >
                <AccordionSummary>{t('set.p3.title')}</AccordionSummary>
                <AccordionDetails>
                    <Card variant="outlined" sx={{p: 2}}>
                        <Typography level="body-sm" mb={2}>{t('set.p3.t1')}</Typography>
                        <Typography level="body-sm" mb={2}>{t('set.p3.t2')}</Typography>
                        <Typography level="body-sm" mb={2}>{t('set.p3.t3')}</Typography>
                        <Row className="g-2">
                            <Col><Typography variant="body1">{t('set.p3.blends')}</Typography>
                                <ThemeProvider theme={theme}>
                                    <Switch
                                        disabled={progress || rendered == null}
                                        checked={useBlends}
                                        color="primary"
                                        onChange={(event) => handleUseBlends(event.target.checked)}
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col xs={8}><Typography variant="body1">{t('set.p3.minblends')}</Typography></Col>
                            <Col xs={4}><Typography variant="body1">{minBlendsPercentage}%</Typography></Col>
                            <Col>
                                <ThemeProvider theme={theme}>
                                    <Slider aria-label="minCrossesPercentage"
                                            value={minBlendsPercentage} marks
                                            step={0.05} min={0} max={1}
                                            valueLabelDisplay="auto"
                                            onChange={handleMinBlendsChange}
                                            disabled={progress || rendered == null || !useBlends}
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row>
                        <div align="center">
                            <ThemeProvider theme={theme}>
                            <Button
                                startIcon={<GradientRoundedIcon/>}
                                onClick={() => {
                                    dispatch(calculate());
                                    setIndex(2);
                                }}
                                disabled={progress}
                                color="secondary"
                                variant="contained"
                                size="small"
                                style={{width: '100%'}}
                            >
                                {t('set.calc')}
                            </Button>
                            </ThemeProvider>
                        </div>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={index === 3}
                onChange={(event, expanded) => {
                    setIndex(expanded ? 3 : null);
                }}
            >
                <AccordionSummary>{t('set.p4.title')}</AccordionSummary>
                <AccordionDetails>
                    <Card variant="outlined" sx={{p:2}}>
                        <Typography level="body-sm" mb={2}>{t('set.p4.t1')}</Typography>
                        <Typography level="body-sm" mb={2}>{t('set.p4.t2')}</Typography>
                        <Typography level="body-sm" mb={2}>{t('set.p4.t3')}</Typography>
                        <Typography level="body-sm" mb={2}>{t('set.p4.t4')}</Typography>
                        <Row className="g-2">
                            <Col xs={8}><Typography variant="body1">{t('set.p4.radius')}</Typography></Col>
                            <Col xs={4}><Typography variant="body1">{radius}</Typography></Col>
                            <Col>
                                <ThemeProvider theme={theme}>
                                    <Slider aria-label={t('set.p4.radius')}
                                            value={radius} marks
                                            step={1} min={3} max={12}
                                            valueLabelDisplay="auto"
                                            onChange={handleRadiusChange}
                                            disabled={progress || rendered==null }
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col xs={8}><Typography variant="body1">{t('set.p4.colors')}</Typography></Col>
                            <Col xs={4}><Typography variant="body1">{nearestColors}</Typography></Col>
                            <Col>
                                <ThemeProvider theme={theme}>
                                    <Slider aria-label={t('set.p4.colors')}
                                            value={nearestColors} marks
                                            step={1} min={1} max={8}
                                            valueLabelDisplay="auto"
                                            onChange={handleNearestColorsChange}
                                            disabled={progress || rendered==null }
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col><Typography variant="body1">{t('set.p4.show')}</Typography>
                                <ThemeProvider theme={theme}>
                                    <Switch
                                        disabled={progress || rendered == null}
                                        checked={showSingles}
                                        color="primary"
                                        onChange={(event) => handleShowSingles(event.target.checked)}
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row>
                        <Stack direction="row" spacing={2} sx={{mb: 1, mt:4}} alignItems="center">
                            <ThemeProvider theme={theme}>
                            <Button
                                startIcon={<SearchOffRoundedIcon/>}
                                onClick={() => {dispatch(findSingles());}}
                                disabled={progress}
                                color="primary"
                                variant="contained"
                                size="small"
                                style={{width: '100%'}}
                            >
                                {t('set.find')}
                            </Button>
                            <Button
                                startIcon={<AutoFixHighRoundedIcon/>}
                                onClick={() => {dispatch(fixSingles());}}
                                disabled={progress}
                                color="success"
                                variant="contained"
                                size="small"
                                style={{width: '100%'}}
                            >
                                {t('set.fix')}
                            </Button>
                            </ThemeProvider>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{mb: 1, mt:4}} alignItems="center">
                            <ThemeProvider theme={theme}>
                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                startIcon={<SkipNextRoundedIcon/>}
                                onClick={() => {dispatch(setShowSingles(false)); dispatch(setSelectedColor(null)); setIndex(4)}}
                                style={{width: '100%'}}
                            >
                                {t('set.next')}
                            </Button>
                            </ThemeProvider>
                        </Stack>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={index === 4}
                onChange={(event, expanded) => {
                    setIndex(expanded ? 4 : null);
                }}
            >
                <AccordionSummary>{t('set.p5.title')}</AccordionSummary>
                <AccordionDetails>
                    <Card variant="outlined" sx={{p: 2}}>
                        <Typography level="body-sm" mb={2}>{t('set.p5.t1')}</Typography>
                        <Typography level="body-sm" mb={2}>{t('set.p5.t2')}</Typography>
                        <Typography level="body-sm" mb={2}>{t('set.p5.t3')} <a href="https://cs-paradise.com/">CS Paradise</a>, <a href={"https://www.ursasoftware.com/macstitch/"}>MacStitch/WinStitch</a> {t('set.p5.t4')}</Typography>
                        <Typography level="body-sm" mb={2}>{t('set.p5.t5')}</Typography>
                        <Stack direction="row" spacing={2} sx={{mb: 3, mt:3}} alignItems="center">
                            <TextField
                                label={t('set.p5.labeltitle')}
                                variant="standard"
                                color="warning"
                                value={name}
                                style={{width: '100%'}}
                                onChange={(event) => {
                                    dispatch(setName(event.target.value));
                                }}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{mb: 1, mt:1}} alignItems="center">
                            <ThemeProvider theme={theme}>
                            <Button
                                startIcon={<FileDownloadRoundedIcon/>}
                                onClick={() => {dispatch(exportPattern());setIndex(5)}}
                                disabled={progress}
                                color="success"
                                variant="contained"
                                size="small"
                                style={{width: '100%'}}
                            >
                                {t('set.export_oxs')}
                            </Button>
                            </ThemeProvider>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{mb: 1, mt:1}} alignItems="center">
                            <ThemeProvider theme={theme}>
                                <Button
                                    startIcon={<CalculateRoundedIcon/>}
                                    onClick={() => {setTcOpen(true)}}
                                    disabled={progress}
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    style={{width: '100%'}}
                                >
                                    {t('set.count')}
                                </Button>
                            </ThemeProvider>
                        </Stack>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={index === 5}
                onChange={(event, expanded) => {
                    setIndex(expanded ? 5 : null);
                }}
            >
                <AccordionSummary>{t('set.p6.title')}</AccordionSummary>
                <AccordionDetails>
                    <Card variant="outlined" sx={{p: 2}}>
                        <Typography level="body-sm" mb={2}>{t('set.p6.text')}</Typography>
                        <Stack direction="row" spacing={2} sx={{mb: 1, mt:1}} alignItems="center">
                            <ThemeProvider theme={theme}>
                                <Link to={'/' + i18n.language + '/about'}>
                                    <Button
                                        startIcon={<FileDownloadRoundedIcon/>}
                                        disabled={progress}
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        style={{width: '100%'}}
                                    >
                                        {t('set.thankyou')}
                                    </Button>
                                </Link>
                            </ThemeProvider>
                        </Stack>
                    </Card>
                </AccordionDetails>
            </Accordion>
        </AccordionGroup>
        </>
    );
}

export default Settings;
