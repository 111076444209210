import { createSlice } from '@reduxjs/toolkit';
import DMC from "../utils/DMC";

const initialState = {
    palette: DMC,
    customPalette: null,
    rendered: null,
    pixels: [],
    singles: [],
    selectedColor: null,
    progress: null,
    minCrossesPercentage: 0.3,
    minBlendsPercentage: 0.3,
    useBlends: false,
    radius: 6,
    nearestColors: 5,
    showSingles: false,
    count: "14",
    stitch: "2",
    strands: "2",
    name: "No name",
    status: null,
};

export const patternSlice = createSlice({
    name: 'pattern',
    initialState,
    reducers: {
        setSelectedColor: (state, action) => {
            state.selectedColor = action.payload;
        },
        removeColor: (state, action) => {
            delete state.palette[action.payload];
        },
        setPixel: (state, action) => {
            const pixels = action.payload;
            state.pixels = [];
            for(let code in state.palette) {
                delete state.palette[code].x;
            }
            pixels.forEach((pixel) => {
                state.pixels.push(pixel);
                state.palette[pixel].x = state.palette[pixel].x !== undefined ? state.palette[pixel].x+1 : 1;
            })
        },
        setSingles: (state, action) => {
            const singles = action.payload;
            state.singles = [];
            state.singles.push(...singles);
        },
        setProgress: (state, action) => {
            state.progress = action.payload;
        },
        setMinCrosses: (state, action) => {
            state.minCrossesPercentage = action.payload;
        },
        setMinBlends: (state, action) => {
            state.minBlendsPercentage = action.payload;
        },
        setRadius: (state, action) => {
            state.radius = action.payload;
        },
        setNearestColors: (state, action) => {
            state.nearestColors = action.payload;
        },
        setRender: (state, action) => {
            if (action.payload === null) {
                state.selectedColor = null;
            }
            state.rendered = action.payload;
        },
        calculate: (state) => {
            state.pixels = [];
            for (let color in state.palette) {
                state.palette[color].x = 0;
            }
        },
        findSingles: (state) => {
            state.singles = [];
        },
        fixSingles: (state) => {
            state.singles = [];
        },
        dropPalette: (state) => {
            state.rendered = null;
            state.palette = DMC;
        },
        setPalette: (state, action) => {
            state.palette = action.payload;
        },
        dropBlends: (state) => {
            state.rendered = null;
            for (let color in state.palette) {
                if (state.palette[color].code.includes('+')) {
                    delete state.palette[color];
                }
            }
        },
        setUseBlends: (state, action) => {
            state.useBlends = action.payload;
        },
        setShowSingles: (state, action) => {
            state.showSingles = action.payload;
        },
        setBlends: (state, action) => {
            state.palette = Object.assign({}, state.palette, action.payload);
        },
        exportPattern: () => {},
        setCount: (state, action) => {
            state.count = action.payload;
        },
        setStitch: (state, action) => {
            state.stitch = action.payload;
        },
        setStrands: (state, action) => {
            state.strands = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
            if (process.env.NODE_ENV === 'development') {
                console.log(action.payload);
            }
        },
        setCustomPalette: (state, action) => {
            state.customPalette = action.payload;
        }
    },
});

export const {
    setSelectedColor,
    removeColor,
    setPixel,
    setMinCrosses,
    setMinBlends,
    setProgress,
    setRender,
    calculate,
    dropPalette,
    dropBlends,
    setUseBlends,
    setBlends,
    setRadius,
    setNearestColors,
    setShowSingles,
    findSingles,
    fixSingles,
    setSingles,
    exportPattern,
    setCount,
    setStitch,
    setStrands,
    setName,
    setStatus,
    setPalette,
    setCustomPalette
} = patternSlice.actions;

export default patternSlice.reducer;
