import {useDispatch, useSelector} from "react-redux";
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import {Tooltip} from "@mui/joy";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React from "react";
import {removeColor, setSelectedColor} from "../reducers/patternSlice";
import {useTranslation} from "react-i18next";
function Palette(){
    const palette= useSelector((state) => state.pattern.palette);
    const selectedColor = useSelector((state) => state.pattern.selectedColor);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSelect = (color) => {
        dispatch(setSelectedColor(color));
    };
    const handleRemove = (params) => {
        dispatch(removeColor(params.row.hex));
    };

    const columns = [
        { field: 'id', headerName: 'DMC', type: 'string', flex:1},
        {
            field: 'hue',
            headerName: t('palette.color'),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <Tooltip arrow color="primary" placement="left" size="md" variant="soft" title={params.row.name}>
                    <div className={"dmc" + ( params.row.selected ? " selected" : "") }
                         style={{ background: params.row.background }}
                         onClick={()=>handleSelect( params.row.selected ? null : params.row.hex )}
                    >
                        {params.row.hue}
                    </div>
                 </Tooltip>
            ),
        },
        { field: 'usage', headerName: t('palette.usage'), type: 'number', flex:1 },
        {
            field: 'actions',
            type: 'actions',
            flex:1,
            getActions: (params) => [
                <GridActionsCellItem onClick={()=>handleRemove(params)} icon={<ClearRoundedIcon />} label="Remove" />,
            ]
        }
    ];
    const rows = Object.keys(palette).map((key) => ({
        id: palette[key].code,
        hex: key,
        hue: palette[key].h,
        usage: palette[key].x,
        name: palette[key].name,
        background: palette[key].hexA == null ? key
            : 'linear-gradient(to right, ' + palette[key].hexA + ' 33%, ' + palette[key].hexB + ' 66%)',
        selected: selectedColor === key
    }));

    return (
        <div style={{ height: '700px', width: '100%' }}>
        <DataGrid
            rows={rows}
            columns={columns}
            density={'compact'}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableRowSelectionOnClick
            rowSelection={false}
            disableSelectionOnClick
            initialState={{
                sorting: {
                    sortModel: [{ field: 'usage', sort: 'desc' }],
                },
            }}
            onCellClick={(params, event) => event.stopPropagation()}
            pageSizeOptions={[100]}
        />
        </div>
    );
}

export default Palette;
