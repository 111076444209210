import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    file: null,
    width: 200,
    height: 200,
    brightness: 0,
    chroma: 0,
    progress: false
};

export const adjustedSlice = createSlice({
    name: 'adjusted',
    initialState,
    reducers: {
        setAdjustedFile: (state, action) => {
            state.file = action.payload;
        },
        adjust: (state, action) => {
            state.width = action.payload.width;
            state.height = action.payload.height;
            state.brightness = action.payload.brightness;
            state.chroma = action.payload.chroma;
        },
        setProgress: (state, action) => {
            state.progress = action.payload;
        },
        resetAdjustedFile: (state) => {
            state.file = null;
        }
    },
});

export const {
    setAdjustedFile,
    setProgress,
    adjust,
    resetAdjustedFile } = adjustedSlice.actions;

export default adjustedSlice.reducer;
