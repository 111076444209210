import { takeLatest, put, select, call } from 'redux-saga/effects';
import { setAdjustedFile, adjust } from '../reducers/adjustedSlice';

import {dropPalette, setRender} from "../reducers/patternSlice";

import { instantiate } from "../utils/xboss/xboss.js";

let byteSize = (500 * 500) << 2;
const memory = new WebAssembly.Memory({ initial: ((byteSize + 0xffff) & ~0xffff) >>> 16 });
const { lch, resize } = await instantiate(await WebAssembly.compileStreaming(fetch( new URL("../utils/xboss/xboss.wasm", import.meta.url))), {
    env: {
        memory
    },
});

function* watchAdjustImage(action) {
    const file = yield select(state => state.file.file);
    try {
        const img = new Image();
        img.src = file;
        const data = yield call(adjustImage, img, action.payload.width, action.payload.height, action.payload.brightness, action.payload.chroma);
        yield put(setAdjustedFile(data));
        yield put(setRender(null));
        yield put(dropPalette());
    } catch (error) {
        // Handle errors gracefully, e.g., log the error or dispatch a failure action
        console.error('Error adjusting image:', error);
    }
}
function adjustImage(img, w, h, brightness, saturation) {
    return new Promise((resolve) => {
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            resize(w, h);

            ctx.imageSmoothingEnabled = true;
            ctx.drawImage(img, 0, 0, w, h);
            if (brightness !== 0 || saturation !== 0) {
                const iD = ctx.getImageData(0, 0, canvas.width, canvas.height);
                let buffer = new Uint8Array(memory.buffer);
                let argb = new Uint8Array(iD.data.buffer);
                buffer.set(argb, 0);
                lch(brightness / 100, saturation / 100, 0, (w * h) << 2);
                argb.set(buffer.subarray(0, (w * h) << 2));
                ctx.putImageData(iD, 0, 0);
            }
            resolve(canvas.toDataURL());
        }
    });
}

export default function* adjustedSaga() {
    yield takeLatest( adjust.type, watchAdjustImage);
}
