import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import TransformRoundedIcon from '@mui/icons-material/TransformRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import FaceRoundedIcon from '@mui/icons-material/FaceRounded';
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';
import React from "react";
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

function Navigation() {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    return (
        <>
            <nav className="d-none d-lg-flex" id="nav">
                <NavLink to={"/" + lang + "/"} end={"true"}>
                    <EmojiPeopleRoundedIcon/> {t('nav.welcome')}
                </NavLink>
                <NavLink to={"/" + lang +'/select'} end={"true"}>
                    <ImageSearchRoundedIcon/> {t('nav.select')}
                </NavLink>
                <NavLink to={"/" + lang + '/adjust'} end={"true"}>
                    <TransformRoundedIcon/> {t('nav.adjust')}
                </NavLink>
                <NavLink to={"/" + lang + '/create'} end={"true"}>
                    <DesignServicesRoundedIcon/> {t('nav.create')}
                </NavLink>
                <NavLink to={"/" + lang + '/about'} end={"true"}>
                    <FaceRoundedIcon/> {t('nav.about')}
                </NavLink>
            </nav>
            <nav className="d-flex d-lg-none justify-content-center" id="nav">
                <NavLink to={"/" + lang + "/"} end={"true"}>
                    <EmojiPeopleRoundedIcon/>
                </NavLink>
                <NavLink to={"/" + lang +'/select'} end={"true"}>
                    <ImageSearchRoundedIcon/>
                </NavLink>
                <NavLink to={"/" + lang + '/adjust'} end={"true"}>
                    <TransformRoundedIcon/>
                </NavLink>
                <NavLink to={"/" + lang + '/create'} end={"true"}>
                    <DesignServicesRoundedIcon/>
                </NavLink>
                <NavLink to={"/" + lang + '/about'} end={"true"}>
                    <FaceRoundedIcon/>
                </NavLink>
            </nav>
        </>
    );
}

export default Navigation;
