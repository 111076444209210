import { combineReducers } from '@reduxjs/toolkit';
import fileReducer from './fileSlice';
import adjustedReducer from './adjustedSlice';
import patternReducer from './patternSlice';

const rootReducer = combineReducers({
    file: fileReducer,
    adjusted: adjustedReducer,
    pattern: patternReducer
});

export default rootReducer;
