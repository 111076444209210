import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../utils/theme';
import Button from "@mui/material/Button";
import btc from './btc.svg';
import eth from './eth.svg';
import paypal from './paypal.svg';
import Snackbar from "@mui/material/Snackbar";
import Avatar from "@mui/material/Avatar";
import {Card} from "@mui/joy";
import { Helmet } from "react-helmet";

export default function About() {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);
    return(
            <Row className="gx-4">
                <Helmet>
                    <link rel="canonical" href={"https://xboss.pro/" + i18n.language +"/about"} />
                </Helmet>
                <Col lg={3}>
                    <ThemeProvider theme={theme}>
                    <div align="center">
                        <Avatar alt="Nick Shtansky" src="/images/nick.jpg" sx={{width: '200px', height: '200px'}}/>
                        <br/>
                        <Typography variant="h6" gutterBottom>{t('about.how')}</Typography>
                    <a href="https://paypal.me/xbosspro">
                        <Button variant="text" color="primary" size="large">
                            <img src={paypal} alt="PayPal" height="32" style={{marginRight: '20px'}}/> {t('about.donate')}
                        </Button>
                    </a>
                    <br/>
                    <Button variant="text" color="secondary" size="large"
                            onClick={() => {
                                navigator.clipboard.writeText('bc1q4cgyd7c7el7cupdujf3d8u3nq8xn0fuzvr7etf');
                                setOpen(true);
                            }}
                    >
                        <img src={btc} alt="BTC" height="32" style={{marginRight: '20px'}}/> {t('about.donateBTC')}
                    </Button>
                    <br/>
                    <Button variant="text" color="secondary" size="large"
                            onClick={() => {
                                navigator.clipboard.writeText('0xc778B258A879363797113CbD3cb37d78B9a4679b');
                                setOpen(true);
                            }}
                    >
                        <img src={eth} alt="ETH" height="32" style={{marginRight: '20px'}}/> {t('about.donateETH')}
                    </Button>
                    </div>
                    <Snackbar
                        open={open}
                        autoHideDuration={3000}
                        onClose={() => setOpen(false)}
                        message={t('about.copied')}
                    />
                    </ThemeProvider>
                </Col>
                <Col lg={9}>
                    <Card variant="plain" color="neutral" sx={{boxShadow: 'lg'}}>
                        <ThemeProvider theme={theme}>
                    <Typography variant="h5" gutterBottom>{t('about.t1')}</Typography>
                    <Typography variant="h5" gutterBottom>{t('about.t1+')}</Typography>
                    <Typography variant="body2" gutterBottom>{t('about.t2')}</Typography>
                    <Typography variant="body2" gutterBottom>{t('about.t3')}</Typography>
                    <Typography variant="body2" gutterBottom>{t('about.t4')}</Typography>
                    <Typography variant="body2" gutterBottom>{t('about.t5')}</Typography>
                    <ul>
                        <li><Typography variant="body2" gutterBottom>{t('about.li1')}</Typography></li>
                        <li><Typography variant="body2" gutterBottom>{t('about.li2')}</Typography></li>
                        <li><Typography variant="body2" gutterBottom>{t('about.li3')}</Typography></li>
                        <li><Typography variant="body2" gutterBottom>{t('about.li4')}</Typography></li>
                        <li><Typography variant="body2" gutterBottom>{t('about.li5')}</Typography></li>
                        <li><Typography variant="body2" gutterBottom>{t('about.li6')} <a href="mailto:art@xboss.pro">art@xboss.pro</a></Typography></li>
                        <li><Typography variant="body2" gutterBottom>{t('about.li7')}</Typography></li>
                    </ul>
                    <Typography variant="body2" gutterBottom>{t('about.t6')}</Typography>

                    <Typography variant="body2" gutterBottom>{t('about.t7')}</Typography>

                    <Typography variant="body2" sx={{mt:3}}>{t('about.t8')}</Typography>
                    <Typography variant="body2" gutterBottom>{t('about.t9')}</Typography>
                    <Typography variant="body2" gutterBottom>{t('about.t10')} <a href="https://www.linkedin.com/in/shtansky/">shtansky</a></Typography>
                        </ThemeProvider>
                        </Card>
                    </Col>
            </Row>
    );
}
