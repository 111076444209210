import MainFrame from '../src/components/MainFrame';
import './App.css';
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';

function App(props) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!['en', 'ru', 'ro', 'ua'].includes(i18n.language)) {
            i18n.changeLanguage('en');
        }
        if (!props.lang && !i18n.language) {
            navigate('/en/');
        } else if (props.lang) {
            i18n.changeLanguage(props.lang).then();
        } else if (i18n.language) {
            navigate('/' + i18n.language + '/');
        }
    }, [props, i18n, navigate]);

    const ld = {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": t('meta.titleshort'),
        "url": t('meta.url')
    };

    return (
        <div className="App">
            <Helmet>
                <html lang={i18n.language}/>
                <title>{t('meta.title')}</title>
                <meta name="description" content={t('meta.description')}/>
                <meta name="robots" content="index, follow"/>

                <meta property="og:type" content="website"/>
                <meta property="og:title" content={t('meta.titleshort')}/>
                <meta property="og:url" content={t('meta.url')}/>
                <meta property="og:image" content="https://xboss.pro/images/fb.jpg"/>
                <meta property="og:description"
                      content={t('meta.description')}/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content={t('meta.titleshort')}/>
                <meta name="twitter:description"
                      content={t('meta.description')}/>
                <meta name="twitter:image" content="https://xboss.pro/images/twi.jpg"/>
                <script type='application/ld+json' dangerouslySetInnerHTML={{__html: JSON.stringify(ld)}}/>
            </Helmet>
            <MainFrame/>
        </div>
    );
}

export default App;
