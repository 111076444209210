// noinspection JSCheckFunctionSignatures

import {useDispatch, useSelector} from "react-redux";
import AspectRatio from "@mui/joy/AspectRatio";
import React, {useState} from "react";
import Slider from '@mui/material/Slider';
import Typography from "@mui/joy/Typography";
import {adjust} from "../reducers/adjustedSlice";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";
import {Accordion, AccordionDetails, AccordionSummary, AccordionGroup, Select, Option} from "@mui/joy";
import {InfoOutlined} from "@mui/icons-material";
import {setCount, setStitch, setStrands} from "../reducers/patternSlice";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../utils/theme';
import {useTranslation} from "react-i18next";
import {Paper, TableBody, Table, TableContainer, TableHead, TableRow} from "@mui/material";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { orange } from '@mui/material/colors';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: orange[800],
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: orange[50],
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function Adjustments() {
    const adjustedFile = useSelector((state) => state.adjusted.file);
    const ratio = useSelector((state) => state.file.ratio);
    const {width, height} = useSelector((state) => state.adjusted);
    const {brightness, chroma, progress} = useSelector((state) => state.adjusted);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const {count, stitch, strands} = useSelector((state) => state.pattern);

    const handleWidth = (event, newValue) => {
        let height = Math.round(newValue / ratio);
        let width = newValue;
        if (height > 400) {
            height = 400;
            width = Math.round(height * ratio);
        }
        dispatch(adjust({width, height, brightness, chroma}));
    }
    const handleHeight = (event, newValue) => {
        let width = Math.round(newValue * ratio);
        let height = newValue;
        if (width > 400) {
            width = 400;
            height = Math.round(width / ratio);
        }
        dispatch(adjust({width, height, brightness, chroma}));
    }

    const handleBrightness = (event, newValue) => {
        dispatch(adjust({width, height, brightness: newValue, chroma}));
    }

    const handleChroma = (event, newValue) => {
        dispatch(adjust({width, height, brightness, chroma: newValue}));
    }

    const [index, setIndex] = useState(0);

    if (adjustedFile === null) {
        return (
            <Container>
                <Helmet>
                    <link rel="canonical" href={"https://xboss.pro/" + i18n.language +"/adjust"} />
                </Helmet>
                <Row className="g-3">
                    <Col lg={12} className="mt-5 justify-content-center" style={{textAlign: 'center'}}>
                        <Typography startDecorator={<InfoOutlined />} justifyContent={"center"} level="title-md" mb={1}>{t('select_file')}</Typography>
                        <ThemeProvider theme={theme}>
                            <Link to={'/' + i18n.language + '/select'}>
                                <Button size="medium" color="secondary" variant="contained"
                                        startIcon={<SkipPreviousRoundedIcon />}
                                        style={{marginTop: '30px'}}
                                >
                                    {t('back')}
                                </Button>
                            </Link>
                        </ThemeProvider>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container>
            <Helmet>
                <link rel="canonical" href={"https://xboss.pro/" + i18n.language +"/adjust"} />
            </Helmet>
            <Row className="g-3">
                <Col lg={6}>
                    <AspectRatio
                        variant="soft"
                        ratio="1/1"
                        objectFit="contain"
                        maxHeight={700}
                    >
                        <img src={adjustedFile} alt='' style={{imageRendering: 'pixelated'}}/>
                    </AspectRatio>
                </Col>
                <Col lg={6}>
                    <AccordionGroup>
                    <Accordion
                        expanded={index === 0}
                        onChange={(event, expanded) => {
                            setIndex(expanded ? 0 : null);
                        }}
                    >
                        <AccordionSummary>{t('adjust.p1.title')}</AccordionSummary>
                        <AccordionDetails>
                            <Card variant="outlined" sx={{p:2}}>
                                <Typography startDecorator={<InfoOutlined />} level="body-sm" mb={2}>{t('adjust.p1.t1')}</Typography>
                                <Row className='g-2'>
                                    <Col md={6} lg={4}>
                                    <Typography variant="body1">{t('adjust.p1.cnt')}:</Typography>
                                    <Select value={count} onChange={(event, value)=>{dispatch(setCount(value))}}>
                                        <Option value="8">8</Option>
                                        <Option value="11">11</Option>
                                        <Option value="14">14</Option>
                                        <Option value="16">16</Option>
                                        <Option value="18">18</Option>
                                        <Option value="20">20</Option>
                                        <Option value="22">22</Option>
                                    </Select>
                                    </Col>
                                    <Col md={6} lg={4}>
                                    <Typography variant="body1">{t('adjust.p1.stitch')}</Typography>
                                    <Select value={stitch} onChange={(event, value)=>{dispatch(setStitch(value))}}>
                                        <Option value="2">{t('adjust.p1.c2')}</Option>
                                        <Option value="1">{t('adjust.p1.c1')}</Option>
                                    </Select>
                                    </Col>
                                    <Col md={6} lg={4}>
                                    <Typography variant="body1">{t('adjust.p1.strands')}</Typography>
                                    <Select value={strands} onChange={(event, value)=>{dispatch(setStrands(value))}}>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                        <Option value="6">6</Option>
                                    </Select>
                                    </Col>
                                </Row>
                                <br/>
                                <Typography variant="body1"><strong>{t('adjust.p1.rec')}</strong></Typography>
                                <TableContainer component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align={"center"}>{t('adjust.p1.cnt')}</StyledTableCell>
                                                <StyledTableCell align={"center"}>{t('adjust.p1.c2')}</StyledTableCell>
                                                <StyledTableCell align={"center"}>{t('adjust.p1.c1')}</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow key="aida8">
                                                <StyledTableCell align={"center"}>8</StyledTableCell>
                                                <StyledTableCell align={"center"}>3-6</StyledTableCell>
                                                <StyledTableCell align={"center"}>6</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="aida11">
                                                <StyledTableCell align={"center"}>11</StyledTableCell>
                                                <StyledTableCell align={"center"}>3</StyledTableCell>
                                                <StyledTableCell align={"center"}>5-6</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="aida14">
                                                <StyledTableCell align={"center"}>14</StyledTableCell>
                                                <StyledTableCell align={"center"}>2-3</StyledTableCell>
                                                <StyledTableCell align={"center"}>4-5</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="aida16">
                                                <StyledTableCell align={"center"}>16</StyledTableCell>
                                                <StyledTableCell align={"center"}>2</StyledTableCell>
                                                <StyledTableCell align={"center"}>3-4</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="aida18">
                                                <StyledTableCell align={"center"}>18</StyledTableCell>
                                                <StyledTableCell align={"center"}>1-2</StyledTableCell>
                                                <StyledTableCell align={"center"}>3</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="aida20">
                                                <StyledTableCell align={"center"}>20</StyledTableCell>
                                                <StyledTableCell align={"center"}>1</StyledTableCell>
                                                <StyledTableCell align={"center"}>2</StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div align="center">
                                    <ThemeProvider theme={theme}>
                                    <Button size="medium" color="secondary" variant="contained"
                                            startIcon={<SkipNextRoundedIcon/>}
                                            onClick={() => setIndex(1)}
                                            style={{marginTop: '30px'}}
                                    >
                                        {t('adjust.p1.next')}
                                    </Button>
                                    </ThemeProvider>
                                </div>
                            </Card>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={index === 1}
                        onChange={(event, expanded) => {
                            setIndex(expanded ? 1 : null);
                        }}
                    >
                        <AccordionSummary>{t('adjust.p2.title')}</AccordionSummary>
                        <AccordionDetails>
                            <Card variant="outlined" sx={{p:2}}>
                                <Typography startDecorator={<InfoOutlined />} level="body-sm" mb={2}>{t('adjust.p2.t1')}</Typography>
                                <Typography startDecorator={<InfoOutlined />} level="body-sm" mb={2}>{t('adjust.p2.t2')}</Typography>
                                <Row className="g-2">
                                    <Col xs={6} xl={2} className="order-0 order-xl-0">
                                        <Typography variant="body1">{t('adjust.p2.w')}</Typography>
                                    </Col>
                                    <Col xl={6} className="order-2 order-xl-1">
                                        <ThemeProvider theme={theme}>
                                        <Slider
                                            aria-label="Width"
                                            value={width}
                                            step={10}
                                            marks
                                            min={10}
                                            max={400}
                                            color="primary"
                                            valueLabelDisplay="auto"
                                            onChange={handleWidth}
                                            disabled={progress}
                                        />
                                        </ThemeProvider>
                                    </Col>
                                    <Col xs={6} xl={4} className="order-1 order-xl-2">
                                        <Typography variant="body1">{width} <span style={{color:'#999999'}}>≈&nbsp;
                                            { Math.round(width / parseInt(count) * 10) / 10 }" ≈&nbsp;
                                            { Math.round(width / parseInt(count) * 25.4) / 10 }{t('adjust.p2.cm')}</span>
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row className="g-2">
                                    <Col xs={6} xl={2} className="order-0 order-xl-0">
                                        <Typography variant="body1">{t('adjust.p2.h')}</Typography>
                                    </Col>
                                    <Col xl={6} className="order-2 order-xl-1">
                                        <ThemeProvider theme={theme}>
                                            <Slider
                                                aria-label="Height"
                                                value={height}
                                                step={10}
                                                marks
                                                min={10}
                                                max={400}
                                                color="primary"
                                                valueLabelDisplay="auto"
                                                onChange={handleHeight}
                                                disabled={progress}
                                            />
                                        </ThemeProvider>
                                    </Col>
                                    <Col xs={6} xl={4} className="order-1 order-xl-2">
                                        <Typography variant="body1">{height} <span style={{color:'#999999'}}>≈&nbsp;
                                            { Math.round(height / parseInt(count) * 10) / 10 }" ≈&nbsp;
                                            { Math.round(height / parseInt(count) * 25.4) / 10 }{t('adjust.p2.cm')}</span>
                                        </Typography>
                                    </Col>
                                </Row>
                                <div align="center">
                                    <ThemeProvider theme={theme}>
                                    <Button size="medium" color="secondary" variant="contained"
                                            startIcon={<SkipNextRoundedIcon/>}
                                            onClick={() => setIndex(2)}
                                            style={{marginTop: '30px'}}
                                    >
                                        {t('adjust.p1.next')}
                                    </Button>
                                    </ThemeProvider>
                                </div>
                            </Card>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={index === 2}
                        onChange={(event, expanded) => {
                            setIndex(expanded ? 2 : null);
                        }}
                    >
                        <AccordionSummary>{t('adjust.p3.title')}</AccordionSummary>
                        <AccordionDetails>
                            <Card variant="outlined" sx={{p: 2}}>
                                <Typography startDecorator={<InfoOutlined/>} level="body-sm" mb={2}>{t('adjust.p3.t1')}</Typography>
                                <Row className="g-2">
                                    <Col xs={10} xl={3} className="order-0 order-xl-0">
                                        <Typography variant="body1">{t('adjust.p3.b')}</Typography>
                                    </Col>
                                    <Col xl={7} className="order-2 order-xl-1">
                                        <ThemeProvider theme={theme}>
                                            <Slider
                                                aria-label={t('adjust.p3.b')}
                                                value={brightness}
                                                step={5}
                                                marks
                                                min={-100}
                                                max={100}
                                                color="primary"
                                                onChange={handleBrightness}
                                                disabled={progress}
                                            />
                                        </ThemeProvider>
                                    </Col>
                                    <Col xs={2} xl={2} className="order-1 order-xl-2">
                                        <Typography variant="body1">{brightness}</Typography>
                                    </Col>
                                </Row>
                                <Row className="g-2">
                                    <Col xs={10} xl={3} className="order-0 order-xl-0">
                                        <Typography variant="body1">{t('adjust.p3.s')}</Typography>
                                    </Col>
                                    <Col xl={7} className="order-2 order-xl-1">
                                        <ThemeProvider theme={theme}>
                                            <Slider
                                                aria-label={t('adjust.p3.s')}
                                                value={chroma}
                                                step={5}
                                                marks
                                                min={-100}
                                                max={100}
                                                color="primary"
                                                onChange={handleChroma}
                                                disabled={progress}
                                            />
                                        </ThemeProvider>
                                    </Col>
                                    <Col xs={2} xl={2} className="order-1 order-xl-2">
                                        <Typography variant="body1">{chroma}</Typography>
                                    </Col>
                                </Row>
                                <div align="center">
                                    <ThemeProvider theme={theme}>
                                        <Link to={'/' + i18n.language + '/create'}>
                                            <Button size="medium" color="secondary" variant="contained"
                                                    startIcon={<SkipNextRoundedIcon/>}
                                                    style={{marginTop: '30px'}}
                                            >
                                                {t('adjust.p1.next')}
                                            </Button>
                                        </Link>
                                    </ThemeProvider>
                                </div>
                            </Card>
                        </AccordionDetails>
                    </Accordion>
                </AccordionGroup>
                </Col>
            </Row>
        </Container>
    );
}

export default Adjustments;
