import React from "react";
import {useSelector} from "react-redux";
import Palette from "./Palette";
import Alert from "@mui/joy/Alert";
import AspectRatio from "@mui/joy/AspectRatio";
import LinearProgress from "@mui/material/LinearProgress";
import Settings from "./Settings";
import Chip from "@mui/material/Chip";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../utils/theme';
import {useTranslation} from "react-i18next";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Typography from "@mui/joy/Typography";
import {InfoOutlined} from "@mui/icons-material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";
import { Helmet } from "react-helmet";

function Pattern() {
    const { t , i18n } = useTranslation();
    const adjusted = useSelector((state) => state.adjusted.file);

    const palette= useSelector((state) => state.pattern.palette);
    const {progress, rendered, status} = useSelector((state) => state.pattern);
    const {width, height} = useSelector((state) => state.adjusted);

    const numberSolids = Object.values(palette).filter(p => !p.code.includes('+')).length;
    const numberBlends = Object.values(palette).filter(p => p.code.includes('+')).length;
    let numberSingles = useSelector((state) => state.pattern.singles.filter(Boolean).length);
    numberSingles = numberSingles > 0 ? numberSingles : '??';

    if (adjusted === null) {
        return (
            <Container>
                <Helmet>
                    <link rel="canonical" href={"https://xboss.pro/" + i18n.language +"/create"} />
                </Helmet>
                <Row className="g-3">
                    <Col lg={12} className="mt-5 justify-content-center" style={{textAlign: 'center'}}>
                        <Typography startDecorator={<InfoOutlined />} justifyContent={"center"} level="title-md" mb={1}>{t('select_file')}</Typography>
                        <ThemeProvider theme={theme}>
                            <Link to={'/' + i18n.language + '/select'}>
                                <Button size="medium" color="secondary" variant="contained"
                                        startIcon={<SkipPreviousRoundedIcon />}
                                        style={{marginTop: '30px'}}
                                >
                                    {t('back')}
                                </Button>
                            </Link>
                        </ThemeProvider>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Row className="g-3">
            <Helmet>
                <link rel="canonical" href={"https://xboss.pro/" + i18n.language +"/create"} />
            </Helmet>
            <Col xl={6}>
                {rendered!==null && <AspectRatio
                    variant="soft"
                    ratio="1/1"
                    objectFit="contain"
                    maxHeight={700}
                >
                    <img src={rendered} alt='' style={{imageRendering: 'pixelated'}}/>
                </AspectRatio>
                }
                {rendered==null && <AspectRatio
                    variant="soft"
                    ratio="1/1"
                    objectFit="contain"
                    maxHeight={700}
                >
                    <img src={adjusted} alt='' style={{imageRendering: 'pixelated', opacity:0.5}}/>
                </AspectRatio>
                }
            </Col>
            <Col xl={6}>
                <Alert color="neutral" variant="soft"
                style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}
                >
                    <ThemeProvider theme={theme}>
                        <Container>
                            <Row className="g-2">
                                <Col md={3} xs={6}>{t('pattern.crosses')}: <Chip color="secondary" label={width * height} /></Col>
                                <Col md={3} xs={6}>{t('pattern.solids')}: <Chip color="secondary" label={numberSolids} /></Col>
                                <Col md={3} xs={6}>{t('pattern.blends')}: <Chip color="secondary" label={numberBlends} /></Col>
                                <Col md={3} xs={6}>{t('pattern.singles')}: <Chip color="secondary" label={numberSingles} /></Col>
                            </Row>
                            {status && <Row>
                                <Col xs={12}>
                                    {status}
                                </Col>
                            </Row>}
                        </Container>
                    </ThemeProvider>
                </Alert>
                <ThemeProvider theme={theme}>
                    {progress && <LinearProgress color="secondary"/>}
                    {!progress && <LinearProgress color="secondary" variant="determinate"  value={0}/>}
                </ThemeProvider>
                <Row className="g-3">
                    <Col xl={6}>
                        <Settings />
                    </Col>
                    <Col xl={6}>
                        <Palette/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Pattern;
