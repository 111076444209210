import { select, takeLatest } from 'redux-saga/effects';
import { exportPattern } from '../reducers/patternSlice';

// Define constants for XML elements
const XML_ELEMENTS = {
    CHART: 'chart',
    FORMAT: 'format',
    PROPERTIES: 'properties',
    PALETTE: 'palette',
    PALETTE_ITEM: 'palette_item',
    FULL_STITCHES: 'fullstitches',
    PART_STITCHES: 'partstitches',
    STITCH: 'stitch',
    PART_STITCH: 'partstitch',
    XGUIDES: 'XGuides',
    YGUIDES: 'YGuides',
    BACK_STITCHES: 'backstitches',
    ORNAMENTS: 'ornaments_inc_knots_and_beads',
    COMMENTBOXES: 'commentboxes',
};

function* exportXML() {
    console.log('Exporting pattern');
    const { palette, pixels, name, count, stitch, strands } = yield select(state => state.pattern);
    const { width, height } = yield select(state => state.adjusted);

    let doc = document.implementation.createDocument('', '', null);
    let chart = doc.createElement(XML_ELEMENTS.CHART);
    chart.appendChild(formatSection(doc));
    chart.appendChild(propertiesSection(doc, width, height, name, count, palette));
    chart.appendChild(paletteSection(doc, palette, stitch, strands, pixels, width, height));
    chart.appendChild(fullstitchesSection(doc, stitch, width, height, pixels, palette));
    chart.appendChild(halfstitchesSection(doc, stitch, width, height, pixels, palette));
    chart.appendChild(doc.createElement(XML_ELEMENTS.XGUIDES));
    chart.appendChild(doc.createElement(XML_ELEMENTS.YGUIDES));
    chart.appendChild(doc.createElement(XML_ELEMENTS.BACK_STITCHES));
    chart.appendChild(doc.createElement(XML_ELEMENTS.ORNAMENTS));
    chart.appendChild(doc.createElement(XML_ELEMENTS.COMMENTBOXES));
    doc.appendChild(chart);

    const serializer = new XMLSerializer();
    const xml = serializer.serializeToString(doc);

    const blob = new Blob([xml], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name.toLowerCase() + '.oxs';
    link.click();
    URL.revokeObjectURL(url);
}

// Additional functions for building different sections of XML
function formatSection(doc) {
    let format = doc.createElement(XML_ELEMENTS.FORMAT);
    format.setAttribute('comments01', 'Designed to allow interchange of basic pattern data between any cross stitch style software');
    format.setAttribute('comments02', 'the `properties` section establishes size, copyright, authorship and software used');
    format.setAttribute('comments03', 'The features of each software package varies, but using XML each can pick out the things it can deal with, while ignoring others');
    format.setAttribute('comments04', 'The basic items are :');
    format.setAttribute('comments05', '`palette`..a set of colors used in the design: palettecount excludes cloth color, which is item 0');
    format.setAttribute('comments06', '`fullstitches`.. simple crosses');
    format.setAttribute('comments07', '`backstitches`.. lines/objects with a start and end point');
    format.setAttribute('comments08', '(There is a wide variety of ways of treating part stitches, knots, beads and so on.)');
    format.setAttribute('comments09', 'Colors are expressed in hex RGB format.');
    format.setAttribute('comments10', 'Decimal numbers use US/UK format where `.` is the indicator - eg 0.5 is `half`');
    format.setAttribute('comments11', 'For readability, please use words not enumerations');
    format.setAttribute('comments12', 'The properties, fullstitches, and backstitches elements should be considered mandatory, even if empty');
    format.setAttribute('comments13', 'element and attribute names are always lowercase');
    return format;
}

function propertiesSection(doc, w, h, name, count, palette) {
    let properties = doc.createElement(XML_ELEMENTS.PROPERTIES);
    properties.setAttribute('oxsversion', '1.0');
    properties.setAttribute('software', 'XBoss');
    properties.setAttribute('software_version', '2024.1');
    properties.setAttribute('chartheight', '' + h);
    properties.setAttribute('chartwidth', '' + w);
    properties.setAttribute('charttitle', name ?? 'No name');
    properties.setAttribute('author', '');
    properties.setAttribute('copyright', '');
    properties.setAttribute('instructions', '');
    properties.setAttribute('stitchesperinch', count);
    properties.setAttribute('stitchesperinch_y', count);
    properties.setAttribute('palettecount', '' + palette.length);
    properties.setAttribute('currentcolor', '1');
    properties.setAttribute('mcentretext', '');
    properties.setAttribute('pdfcoverdocument', '');
    properties.setAttribute('misc1', 'normal');
    properties.setAttribute('misc2', '');
    return properties;
}

function paletteSection(doc, palette, stitch, strands) {
    let paletteElement = doc.createElement(XML_ELEMENTS.PALETTE);
    let paletteItem = doc.createElement(XML_ELEMENTS.PALETTE_ITEM);
    paletteItem.setAttribute('index', '0');
    paletteItem.setAttribute('number', 'cloth');
    paletteItem.setAttribute('name', 'cloth');
    paletteItem.setAttribute('color', 'FEFEFE');
    paletteItem.setAttribute('printcolor', 'FFFFFE');
    paletteItem.setAttribute('blendcolor', 'nil');
    paletteItem.setAttribute('comments', '');
    paletteItem.setAttribute('strands', strands);
    paletteItem.setAttribute('symbol', '0');
    paletteItem.setAttribute('dashpattern', '');
    paletteItem.setAttribute('misc1', '');
    paletteItem.setAttribute('bsstrands', '0');
    paletteItem.setAttribute('bscolor', '000000');
    paletteItem.setAttribute('fkcolor', '000000');
    paletteItem.setAttribute('palrgb_for_printing', 'FFFFFE');
    paletteElement.appendChild(paletteItem);

    let i= 1;
    for (let code in palette) {
        let hex = code.slice(-6);
        let bg = 'nil';

        let paletteItem = doc.createElement(XML_ELEMENTS.PALETTE_ITEM);
        paletteItem.setAttribute('index', '' + i);
        paletteItem.setAttribute('number', 'DMC ' + palette[code].code);
        paletteItem.setAttribute('name', palette[code].code.includes('+') ? palette[code].code : palette[code].name);
        paletteItem.setAttribute('color', hex);
        paletteItem.setAttribute('printcolor', hex);
        paletteItem.setAttribute('blendcolor', bg);
        paletteItem.setAttribute('comments', '');
        paletteItem.setAttribute('strands', strands);
        paletteItem.setAttribute('symbol', '' + i);
        paletteItem.setAttribute('dashpattern', '');
        paletteItem.setAttribute('misc1', '');
        paletteItem.setAttribute('bsstrands', '0');
        paletteItem.setAttribute('bscolor', hex);
        paletteItem.setAttribute('fkcolor', hex);
        paletteItem.setAttribute('palrgb_for_printing', hex);
        paletteElement.appendChild(paletteItem);
        i++;
    }
    return paletteElement;
}

function fullstitchesSection(doc, stitch, w, h, pixels, palette) {
    let full = doc.createElement(XML_ELEMENTS.FULL_STITCHES);
    let newPalette = Object.keys(palette);
    if (stitch === '2') {
        for (let y = 0; y < h; y++) {
            for (let x = 0; x < w; x++) {
                let stitch = doc.createElement(XML_ELEMENTS.STITCH);
                stitch.setAttribute('x', '' + x);
                stitch.setAttribute('y', '' + y);
                stitch.setAttribute('palindex', '' + (newPalette.indexOf(pixels[y * w + x]) + 1));
                full.appendChild(stitch);
            }
        }
    }
    return full;
}

function halfstitchesSection(doc, stitch, w, h, pixels, palette) {
    let half = doc.createElement(XML_ELEMENTS.PART_STITCHES);
    let newPalette = Object.keys(palette);
    if (stitch === '1') {
        for (let y = 0; y < h; y++) {
            for (let x = 0; x < w; x++) {
                let stitch = doc.createElement(XML_ELEMENTS.PART_STITCH);
                stitch.setAttribute('x', '' + x);
                stitch.setAttribute('y', '' + y);
                stitch.setAttribute('palindex1', '' + (newPalette.indexOf(pixels[y * w + x]) + 1));
                stitch.setAttribute('palindex2', '0');
                stitch.setAttribute('direction', '3');
                half.appendChild(stitch);
            }
        }
    }
    return half;
}

export default function* exportSaga() {
    yield takeLatest( exportPattern.type, exportXML );
}
