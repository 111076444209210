export default function Error() {
    return (
        <div className="d-flex" style={{
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
            background: 'white',
            justifyContent: 'center'
        }}
        >
            <img src="/images/error.jpg" alt="error"/>
            <h1>Ooops... Error page</h1>
            <h3>I have no clue how you got here. This site has only five pages.</h3>
        </div>
    );
}
