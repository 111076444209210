import React from "react";
import Navigation from "./Navigation";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LanguageSwitcher from "./LanguageSwitcher";
import logo from './logo.svg';
import {Outlet} from "react-router-dom";

function MainFrame() {
    return (
        <Container fluid="xxl">
            <Row className="align-items-center mt-2">
                <Col lg={3} md={6} className="order-1 order-lg-0 d-lg-none d-block" align="center">
                    <img src={logo} alt='logo' style={{maxWidth: '100%'}}/>
                </Col>
                <Col lg={3} md={6} className="order-1 order-lg-0 d-lg-block d-none">
                    <img src={logo} alt='logo' style={{maxWidth: '100%'}}/>
                </Col>
                <Col lg={7} md={6} className="order-2 order-lg-1" align="center">
                    <Navigation />
                </Col>
                <Col lg={2} className="order-0 order-lg-2">
                    <LanguageSwitcher />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Outlet />
                </Col>
            </Row>
        </Container>
);
}

export default MainFrame;
