// noinspection JSCheckFunctionSignatures

import React, {useState} from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import {useSelector} from "react-redux";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../utils/theme';
import {useTranslation} from "react-i18next";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import DMC from './../utils/DMC';

const DMCnames = {};
for (let code in DMC) {
    DMCnames[DMC[code].code] = DMC[code].name;
}
const meters = {
    '11' : 25.3,
    '14' : 19.6,
    '16' : 16.9,
    '18' : 14.7,
    '20' : 12.7,
    '22' : 11
};

export default function ThreadCount(props) {
    const [reserve, setReserve] = useState(20);
    const { t } = useTranslation();
    const {palette,count,stitch,strands,name} = useSelector((state) => state.pattern);
    const {width, height} = useSelector((state) => state.adjusted);
    const columns = [
        { field: 'id', headerName: 'DMC', type: 'string', flex:1},
        {
            field: 'name',
            headerName: t('set.tc.name'),
            headerAlign: 'center',
            flex: 1
        },
        { field: 'crosses', headerName: t('set.tc.crosses'), type: 'number', flex:1 },
        { field: 'meters', headerName: t('set.tc.meters'), type: 'number', flex:1 },
        { field: 'skeins', headerName: t('set.tc.skeins'), type: 'number', flex:1 },
    ];
    let rows = Object.keys(palette).map((key) => ({
        id: palette[key].code,
        name: palette[key].name,
        crosses: palette[key].x,
    }));

    for (let code in rows) {
        if (rows[code].id.includes('+')) {
            let blended = rows[code].id.split('+');
            let first = rows.filter(r => r.id === blended[0])[0];
            if (first === undefined) {
                first = {
                    id: blended[0],
                    name: DMCnames[blended[0]],
                    crosses: 0
                }
                rows.push(first);
            }
            let second = rows.filter(r => r.id === blended[1])[0];
            if (second === undefined) {
                second = {
                    id: blended[1],
                    name: DMCnames[blended[1]],
                    crosses: 0
                }
                rows.push(second);
            }
            first.crosses += rows[code].crosses / 2;
            second.crosses += rows[code].crosses / 2;
            rows[code].crosses = 0;
        }
    }
    rows = rows.filter(r => r.crosses > 0);

    for (let code in rows) {
        rows[code].meters = rows[code].crosses / 10000 * meters[count] * parseInt(strands) / 2 * parseInt(stitch);
        rows[code].meters = (rows[code].meters * (1 + reserve/100)).toFixed(2);
        rows[code].skeins = (rows[code].meters / 8).toFixed(1);
        if (rows[code].skeins === '0.0') {
            rows[code].skeins = '0.1';
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    fileName: name + "__palette",
                    utf8WithBom: true,
                }}/>
            </GridToolbarContainer>
        );
    }

    return (
        <Modal open={props.open} onClose={() => props.setOpen(false)}>
            <ModalDialog color="neutral" size="lg" variant="plain" sx={{width:'700px', maxWidth:'100%', height: '700px'}}>
                <ModalClose />
                <DialogTitle>{t('set.tc.title')}</DialogTitle>

                <Stack direction="row" spacing={2}>
                    <Typography variant="body1">{t('set.tc.size')} {width}x{height}</Typography>
                    <Typography variant="body1">{t('set.tc.count')} {count}</Typography>
                    <Typography variant="body1">{t('set.tc.strands')} {strands}</Typography>
                    <Typography variant="body1">{t('set.tc.type')} {stitch === '2' ? t('adjust.p1.c2') : t('adjust.p1.c1') }</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Typography variant="body1">{t('set.tc.reserve')}</Typography>
                    <ThemeProvider theme={theme}>
                        <Slider
                            aria-label="Reserve"
                            value={reserve}
                            step={5}
                            marks
                            min={0}
                            max={40}
                            color="primary"
                            valueLabelDisplay="auto"
                            onChange={(event, newValue)=>setReserve(newValue)}
                        />
                    </ThemeProvider>
                    <Typography variant="body1">{reserve}%</Typography>
                </Stack>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    density={'compact'}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableRowSelectionOnClick
                    rowSelection={false}
                    disableSelectionOnClick
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'crosses', sort: 'desc' }],
                        },
                    }}
                    onCellClick={(params, event) => event.stopPropagation()}
                    pageSizeOptions={[100]}
                    slots={{ toolbar: CustomToolbar }}
                />

            </ModalDialog>
        </Modal>
    );
}
