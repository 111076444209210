import React from "react";
import Typography from "@mui/joy/Typography";
import {Card, CardContent, CardOverflow, Tooltip} from "@mui/joy";
import AspectRatio from "@mui/joy/AspectRatio";
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import DirectionsRunRoundedIcon from '@mui/icons-material/DirectionsRunRounded';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { ThemeProvider } from '@mui/material/styles';
import theme from '../utils/theme';
import {useTranslation} from "react-i18next";

function Welcome() {
    const { t, i18n } = useTranslation();

    return(
        <Container fluid="xxl">
            <Helmet>
                <link rel="canonical" href={"https://xboss.pro/" + i18n.language +"/"} />
            </Helmet>
            <Row className="g-4">
                <Col lg={9} md={6} className="order-1 order-md-0">
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg', minHeight: '310px'}}>
                        <Typography level="body-md" mb={1}>{t('story.part1')}</Typography>
                        <Typography level="body-md" mb={1}>{t('story.part2')}</Typography>
                        <Typography level="body-md" mb={1}>{t('story.part3')}</Typography>
                        <Typography level="body-md" mb={1}><span dangerouslySetInnerHTML={{__html: t('story.part4')}}></span></Typography>
                    </Card>
                </Col>
                <Col lg={3} md={6} className="order-0 order-md-1">
                    <Fade duration={3000} arrows={false}>
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg'}}>
                        <CardOverflow>
                            <AspectRatio ratio="1">
                                <img width="342" height="342" src="/images/carousel0.webp" alt=""/>
                            </AspectRatio>
                        </CardOverflow>
                    </Card>
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg'}}>
                        <CardOverflow>
                            <AspectRatio ratio="1">
                                <img width="342" height="342" src="/images/carousel1.webp" alt=""/>
                            </AspectRatio>
                        </CardOverflow>
                    </Card>
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg'}}>
                        <CardOverflow>
                            <AspectRatio ratio="1">
                                <img width="342" height="342" src="/images/carousel2.webp" alt=""/>
                            </AspectRatio>
                        </CardOverflow>
                    </Card>
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg'}}>
                        <CardOverflow>
                            <AspectRatio ratio="1">
                                <img width="342" height="342" src="/images/carousel3.webp" alt=""/>
                            </AspectRatio>
                        </CardOverflow>
                    </Card>
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg'}}>
                        <CardOverflow>
                            <AspectRatio ratio="1">
                                <img width="342" height="342" src="/images/carousel4.webp" alt=""/>
                            </AspectRatio>
                        </CardOverflow>
                    </Card>
                </Fade>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="my-4">
                    <div align={"center"}>
                    <Typography level="h2" mb={1} className={"orange"}>
                        {t('benefits.title')}
                    </Typography>
                    </div>
                </Col>
            </Row>
            <Row className="g-4">
                <Col lg={4} sm={6}>
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg', minHeight: '282px'}}>
                        <CardOverflow>
                            <AspectRatio ratio="3">
                                <Fade duration={3000} arrows={false}>
                                    <img src={"/images/pixelperfect1.webp"} style={{maxWidth: '100%'}} alt=""/>
                                    <img src={"/images/pixelperfect2.webp"} style={{maxWidth: '100%'}} alt=""/>
                                </Fade>
                            </AspectRatio>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="title-lg" className={"orange"}>{t('benefits.p1.title')}</Typography>
                            <Typography level="body-md">
                                {t('benefits.p1.t1')} <strong className={"orange"}>XBoss</strong> {t('benefits.p1.t2')}
                                <Tooltip arrow color="neutral" placement="top" size="md" variant="solid"
                                         sx={{maxWidth: '300px', p: 1}}
                                         title={t('benefits.p1.tt')}
                                >
                                    <strong style={{marginLeft: '5px', marginRight: '5px'}} className={"orange"}><u>CIEDE2000</u></strong>
                                </Tooltip>{t('benefits.p1.t3')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col lg={4} sm={6}>
                    <Card variant="soft" color="warning" sx={{boxShadow: 'lg', minHeight: '282px'}}>
                        <CardOverflow>
                            <AspectRatio ratio="3" objectFit="cover" style={{objectPosition: '50% 50%'}}>
                                <Fade duration={3000} arrows={false}>
                                    <img src={"/images/fine1.webp"} style={{maxWidth: '100%'}} alt=""/>
                                    <img src={"/images/fine2.webp"} style={{maxWidth: '100%'}} alt=""/>
                                    <img src={"/images/fine3.webp"} style={{maxWidth: '100%'}} alt=""/>
                                </Fade>
                            </AspectRatio>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="title-lg" className={"orange"}>{t('benefits.p2.title')}</Typography>
                            <Typography level="body-md">{t('benefits.p2.t1')}</Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card variant="outlined" sx={{boxShadow: 'lg', minHeight: '282px'}}>
                        <CardOverflow>
                            <AspectRatio ratio="3" variant="soft">
                                <div>
                                <PaidRoundedIcon sx={{ fontSize: '3rem', opacity: 0.2 }} />
                                </div>
                            </AspectRatio>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="body-md" textAlign={"center"}>
                                <span style={{color: '#999999'}}>{t('benefits.ads')}</span>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col lg={4} sm={6}>
                    <Card variant="soft" color="warning" sx={{boxShadow: 'lg', minHeight: '282px'}}>
                        <CardOverflow>
                            <AspectRatio ratio="3" objectFit="cover" style={{objectPosition: '50% 50%'}}>
                                <Fade duration={3000} arrows={false}>
                                    <img src={"/images/singles1.webp"} style={{maxWidth: '100%'}} alt=""/>
                                    <img src={"/images/singles2.webp"} style={{maxWidth: '100%'}} alt=""/>
                                    <img src={"/images/singles3.webp"} style={{maxWidth: '100%'}} alt=""/>
                                    <img src={"/images/singles4.webp"} style={{maxWidth: '100%'}} alt=""/>
                                </Fade>
                            </AspectRatio>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="title-lg" className={"orange"}>{t('benefits.p3.title')}</Typography>
                            <Typography level="body-md">
                                <strong className={"orange"}>XBoss</strong> {t('benefits.p3.text')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col lg={4} sm={6}>
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg', minHeight: '282px'}}>
                        <CardOverflow>
                            <AspectRatio ratio="3" objectFit="cover" style={{objectPosition: '50% 50%'}}>
                                <Fade duration={3000} arrows={false}>
                                    <img src={"/images/blends1.webp"} style={{maxWidth: '100%'}} alt=""/>
                                    <img src={"/images/blends2.webp"} style={{maxWidth: '100%'}} alt=""/>
                                    <img src={"/images/blends3.webp"} style={{maxWidth: '100%'}} alt=""/>
                                </Fade>
                            </AspectRatio>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="title-lg" className={"orange"}>{t('benefits.p4.title')}</Typography>
                            <Typography level="body-md">{t('benefits.p4.text')}</Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col lg={4} sm={6}>
                    <Card variant="soft" color="warning" sx={{boxShadow: 'lg', minHeight: '282px'}}>
                        <CardOverflow>
                            <AspectRatio ratio="3">
                                <img src={"/images/skeins.webp"} alt=""/>
                            </AspectRatio>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="title-lg" className={"orange"}>{t('benefits.p5.title')}</Typography>
                            <Typography level="body-md">
                                <strong className={"orange"}>XBoss</strong> {t('benefits.p5.text')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col lg={4} sm={6}>
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg', minHeight: '282px'}}>
                        <CardOverflow>
                            <AspectRatio ratio="3">
                                <img src={"/images/export.webp"} alt=""/>
                            </AspectRatio>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="title-lg" className={"orange"}>{t('benefits.p6.title')}</Typography>
                            <Typography level="body-md">{t('benefits.p6.t1')}
                                <Tooltip arrow color="neutral" placement="top" size="md" variant="solid" sx={{maxWidth: '300px', p: 1}}
                                         title={t('benefits.p6.tt')}
                                ><strong className={"orange"} style={{marginLeft: '5px'}}><u>{t('benefits.p6.t2')}</u></strong>
                                </Tooltip> {t('benefits.p6.t3')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col lg={4} sm={6}>
                    <Card variant="soft" color="warning" sx={{boxShadow: 'lg', minHeight: '282px'}}>
                        <CardOverflow>
                            <AspectRatio ratio="3">
                                <img src={"/images/free.webp"} alt=""/>
                            </AspectRatio>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="title-lg" className={"orange"}>{t('benefits.p7.title')}</Typography>
                            <Typography level="body-md"><strong className={"orange"}>XBoss</strong> {t('benefits.p7.text')}</Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col lg={4} sm={6}>
                    <Card variant="soft" color="neutral" sx={{boxShadow: 'lg', minHeight: '282px'}}>
                        <CardOverflow>
                            <AspectRatio ratio="3">
                                <img src={"/images/safe.webp"} alt=""/>
                            </AspectRatio>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="title-lg" className={"orange"}>{t('benefits.p8.title')}</Typography>
                            <Typography level="body-md">
                                <strong className={"orange"}>XBoss</strong> {t('benefits.p8.text')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col lg={12}>
                    <Stack direction="row" spacing={3} alignItems="center" justifyContent="center">
                        <Typography level="h1" className={"orange"}>{t('benefits.call')}</Typography>
                        <ThemeProvider theme={theme}>
                            <Link to={'/' + i18n.language + '/select'}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    startIcon={<DirectionsRunRoundedIcon />}
                                >
                                    {t('benefits.button')}
                                </Button>
                            </Link>
                        </ThemeProvider>
                    </Stack>
                </Col>
            </Row>
        </Container>
    )
}

export default Welcome;
