import adjustedSaga from './adjustedSaga';
import fileSaga from './fileSaga';
import patternSaga from './patternSaga';
import exportSaga from './exportSaga';
import { fork } from 'redux-saga/effects';

export default function* rootSaga() {
  yield fork(adjustedSaga);
  yield fork(fileSaga);
  yield fork(patternSaga);
  yield fork(exportSaga);
}
