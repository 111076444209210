import {FileUploader} from "react-drag-drop-files";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setFile, resetFile} from "../reducers/fileSlice";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from '@mui/material/Button';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../utils/theme';
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function SelectImage() {
    const file = useSelector((state) => state.file.file);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const handleChange = (file) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const fileContents = event.target.result; // Textual content or binary data
            dispatch(setFile(fileContents));
        };
        fileReader.readAsDataURL(file);
    };
    return(
        <>
            <Helmet>
                <link rel="canonical" href={"https://xboss.pro/" + i18n.language +"/select"} />
            </Helmet>
            {file == null &&
                <FileUploader handleChange={handleChange} name="file" types={['JPG', 'JPEG', 'PNG', 'WEBP']} classes="drop_zone">
                    <div>
                        <h2 style={{color:'#e98f33'}}><u>{t('select.upload')}</u> {t('select.rest')}</h2>
                    </div>
                </FileUploader>
            }
            {file != null &&
                <div style={{textAlign: 'center'}}>
                <AspectRatio
                    variant="plain"
                    ratio="1/1"
                    objectFit="contain"
                    maxHeight={700}
                >
                    <img src={file} alt='' />
                </AspectRatio>
                    <br/>
                    <ThemeProvider theme={theme}>
                        <Button size="medium" variant="contained" color="primary"
                                startIcon={<DeleteRoundedIcon />}
                                onClick={() => dispatch(resetFile())}
                                sx={{ mt:1}}
                        >
                            {t('select.another')}
                        </Button>
                        <Link to={'/' + i18n.language + '/adjust'}>
                            <Button size="medium" variant="contained" color="secondary"
                                    startIcon={<SkipNextRoundedIcon />}
                                    sx={{ ml: 1, mt:1}}
                            >
                                {t('select.next')}
                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
            }
        </>
    )
}

export default SelectImage;
