import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {store} from './store';
import App from './App';
import Error from './components/Error';
import '@fontsource/roboto/500.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './i18n';
import './index.css';
import Welcome from "./components/Welcome";
import SelectImage from "./components/SelectImage";
import Adjustments from "./components/Adjustments";
import Pattern from "./components/Pattern";
import About from "./components/About";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Error />,
    },
    {
        path: "/en/",
        element: <App lang="en"/>,
        errorElement: <Error />,
        children: [
            {
                path: "/en/",
                element: <Welcome />
            },
            {
                path: "/en/select",
                element: <SelectImage />
            },
            {
                path: "/en/adjust",
                element: <Adjustments />
            },
            {
                path: "/en/create",
                element: <Pattern />
            },
            {
                path: "/en/about",
                element: <About />
            }
        ],
    },
    {
        path: "/ru/",
        element: <App lang="ru"/>,
        errorElement: <Error />,
        children: [
            {
                path: "/ru/",
                element: <Welcome />
            },
            {
                path: "/ru/select",
                element: <SelectImage />
            },
            {
                path: "/ru/adjust",
                element: <Adjustments />
            },
            {
                path: "/ru/create",
                element: <Pattern />
            },
            {
                path: "/ru/about",
                element: <About />
            }
        ],
    },
    {
        path: "/ro/",
        element: <App lang="ro"/>,
        errorElement: <Error />,
        children: [
            {
                path: "/ro/",
                element: <Welcome />
            },
            {
                path: "/ro/select",
                element: <SelectImage />
            },
            {
                path: "/ro/adjust",
                element: <Adjustments />
            },
            {
                path: "/ro/create",
                element: <Pattern />
            },
            {
                path: "/ro/about",
                element: <About />
            }
        ],
    },
    {
        path: "/ua/",
        element: <App lang="ua"/>,
        errorElement: <Error />,
        children: [
            {
                path: "/ua/",
                element: <Welcome />
            },
            {
                path: "/ua/select",
                element: <SelectImage />
            },
            {
                path: "/ua/adjust",
                element: <Adjustments />
            },
            {
                path: "/ua/create",
                element: <Pattern />
            },
            {
                path: "/ua/about",
                element: <About />
            }
        ],
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <RouterProvider router={router} />
      </Provider>
  </React.StrictMode>
);
