import { takeEvery, put, call } from 'redux-saga/effects';
import { setFile, setRatio } from '../reducers/fileSlice';
import { adjust, setAdjustedFile } from '../reducers/adjustedSlice';
import { dropPalette, setRender } from '../reducers/patternSlice';

function* watchSetFile(action) {
    let img;
    try {
        img = new Image();
        img.src = action.payload;

        const {width, ratio} = yield call(waitForImageLoad, img);
        yield put(setRatio(ratio));
        yield put(adjust({ width: width<=300 ? width : 200, height: Math.round( (width<=300 ? width : 200) / ratio), brightness: 1, chroma: 1 }));
        yield put(dropPalette());
        yield put(setRender(null));
        yield put(setAdjustedFile(null));
    } catch (error) {
        console.error('Error calculating image ratio:', error);
    } finally {
        if (img) {
            img.onload = null;
            img.onerror = null;
        }
    }
}

function waitForImageLoad(img) {
    return new Promise((resolve, reject) => {
        const onLoad = () => {
            resolve(
                {
                    width: img.naturalWidth,
                    height: img.naturalHeight,
                    ratio: img.naturalWidth / img.naturalHeight
                }
            );
            cleanup();
        };

        const onError = (error) => {
            reject(error);
            cleanup();
        };

        const cleanup = () => {
            img.removeEventListener('load', onLoad);
            img.removeEventListener('error', onError);
        };

        img.addEventListener('load', onLoad);
        img.addEventListener('error', onError);
    });
}

export default function* fileSaga() {
    yield takeEvery(setFile.type, watchSetFile);
}
