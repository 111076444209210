import { takeLatest, put, select, call, fork} from 'redux-saga/effects';
import {
    setProgress,
    setPixel,
    findSingles,
    fixSingles,
    calculate,
    setRender,
    removeColor,
    setBlends,
    setUseBlends,
    setSelectedColor,
    setSingles,
    setShowSingles,
    setStatus, setCustomPalette, setPalette
} from '../reducers/patternSlice';
import chroma from "chroma-js";
import i18next from './../i18n';
import DMC from "../utils/DMC";

let iDa = null;

const points = [
    [0, 100],
    [10, 50],
    [20, 30],
    [50, 15],
    [100, 8],
    [490, 4]
];

function maxDistance(colors) {
    let i;
    if (colors < 10) {
        i = 1;
    } else if (colors < 20) {
        i = 2;
    } else if (colors < 50) {
        i = 3;
    } else if (colors < 100) {
        i = 4;
    } else {
        i = 5;
    }
    let m = (points[i][1] - points[i-1][1])/(points[i][0] - points[i-1][0]);
    let b = points[i-1][1] - m * points[i-1][0];

    // y = mx + b
    return m * colors + b;
}


function* calculatePattern() {
    yield put(setStatus(i18next.t('status.calc')));
    yield put(setRender(null));
    yield put(setSingles([]));
    yield put(setProgress(true));
    const file = yield select(state => state.adjusted.file);
    const img = new Image();
    img.src = file;
    iDa = yield call(getData, img);
    const palette = yield select(state => state.pattern.palette);
    const newPalette = [];
    for (let id in palette) {
        newPalette.push(palette[id].hex);
    }
    const worker = new Worker('/worker.js');
    worker.postMessage({'cmd': 'calc', 'data': { "imageData" : iDa, "palette" : newPalette }});
    const pixels = yield call(getPixelsFromWorker, worker);
    yield put(setPixel(pixels));
    yield put(setStatus(i18next.t('status.pixels')));


    let cleaned = false;
    if (Object.keys(palette).length > 400) {
        cleaned = yield call(reduceColors, 5);
    }
    if (!cleaned) {
        yield fork(cleanUpPalette);
    }
    yield put(setProgress(false));
}

function* calcSingles() {
    yield put(setStatus(i18next.t('status.calcsingles')));
    yield put(setProgress(true));
    const pixels = yield select(state => state.pattern.pixels);
    const w = yield select(state => state.adjusted.width);
    const h = yield select(state => state.adjusted.height);
    const radius = yield select(state => state.pattern.radius);

    const worker = new Worker('/worker.js');
    worker.postMessage({'cmd': 'singles', 'data': { "pixels" : pixels, "w" : w, "h" : h, "r" : radius }});
    const singles = yield call(getSinglesFromWorker, worker);

    yield put(setStatus(i18next.t('status.singlesfound')));
    yield put(setSingles(singles));
    yield put(setProgress(false));
    yield put(setShowSingles(true))
}

function* calcFixSingles() {
    yield put(setStatus(i18next.t('status.fixingsingles')));
    yield put(setProgress(true));
    const pixels = yield select(state => state.pattern.pixels);
    const w = yield select(state => state.adjusted.width);
    const h = yield select(state => state.adjusted.height);
    const radius = yield select(state => state.pattern.radius);
    const nearestColors = yield select(state => state.pattern.nearestColors);
    const palette = yield select(state => state.pattern.palette);
    const newPalette = [];
    for (let id in palette) {
        newPalette.push(palette[id].hex);
    }

    const worker = new Worker('/worker.js');
    worker.postMessage({'cmd': 'fix', 'data': { "pixels" : pixels, "w" : w, "h" : h, "r" : radius, "nearestColors" : nearestColors, "palette" : newPalette }});
    const result = yield call(getFixSinglesFromWorker, worker);

    yield put(setStatus(i18next.t('status.singlesfixed')));
    yield put(setSingles(result.singles));
    yield put(setPixel(result.pixels));
    yield put(setProgress(false));
    yield put(setShowSingles(true));
}

function getData(img) {
    return new Promise((resolve) => {
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            const ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.drawImage(img, 0, 0);
            resolve(ctx.getImageData(0, 0, canvas.width, canvas.height));
        }
    });
}

function getPixelsFromWorker(worker) {
    return new Promise((resolve) => {
        worker.addEventListener('message', function(e) {
            if (e.data.cmd === 'calc') {
                console.log('Calculated pattern');
                resolve(e.data.result);
            }
        }, false);
    });
}

function getSinglesFromWorker(worker) {
    return new Promise((resolve) => {
        worker.addEventListener('message', function(e) {
            if (e.data.cmd === 'singles') {
                resolve(e.data.result);
            }
        }, false);
    });
}

function getFixSinglesFromWorker(worker) {
    return new Promise((resolve) => {
        worker.addEventListener('message', function(e) {
            if (e.data.cmd === 'fix') {
                resolve(e.data.result);
            }
        }, false);
    });
}

function* cleanUpPalette() {
    yield put(setStatus(i18next.t('status.palette')));
    const palette = yield select(state => state.pattern.palette);
    const pixels = yield select(state => state.pattern.pixels);
    const minCrossesPercentage = yield select(state => state.pattern.minCrossesPercentage);
    const minBlendsPercentage = yield select(state => state.pattern.minBlendsPercentage);
    const useBlends = yield select(state => state.pattern.useBlends);
    let cleaned = false;
    for (const id in palette) {
        if (palette[id].x === undefined || palette[id].x === 0) {
            cleaned = true;
            yield put(removeColor(id));
            continue;
        }
        if (palette[id].code.includes('+')) {
            if (palette[id].x < pixels.length / 100 * minBlendsPercentage) {
                cleaned = true;
                yield put(removeColor(id));
            }
        } else {
            if (palette[id].x < pixels.length / 100 * minCrossesPercentage) {
                if (!useBlends) {
                    cleaned = true;
                    yield put(removeColor(id));
                }
            }
        }
    }

    if (cleaned) {
        yield put(calculate());
    } else {
        yield call(draw);
    }
}

function* reduceColors(maxDifference) {
    yield put(setStatus(i18next.t('status.reduce')));
    const palette = yield select(state => state.pattern.palette);
    const codes = Object.keys(palette);
    if (codes.length === 0) {
        return;
    }
    const result = [];

    // Clusterize
    for (const currentNumber of codes) {
        let addedToGroup = false;

        // Check against existing groups
        outerLoop: for (const group of result) {
            for (const number of group) {
                let colorCurrent = chroma(currentNumber);
                let colorInGroup = chroma(number);
                if (chroma.deltaE(colorCurrent, colorInGroup) <= maxDifference) {
                    group.push(currentNumber);
                    addedToGroup = true;
                    break outerLoop;
                }
            }
        }

        // If not added to any existing group, create a new group
        if (!addedToGroup) {
            result.push([currentNumber]);
        }
    }

    let cleaned = false;
    let cleanedCount = 0;
    for (const group of result) {
        let usage = group.map(code => palette[code].x);
        let maxUsage = 0;
        let maxCode = null;
        for (let i=0; i<group.length; i++) {
            if (usage[i] > maxUsage) {
                maxUsage = usage[i];
                maxCode = group[i];
            }
        }
        for (let i=0; i<group.length; i++) {
            if (group[i]!== maxCode) {
                cleaned = true;
                cleanedCount++;
                yield put(removeColor(group[i]));
                delete group[i];
            }
        }
    }
    console.log('reduced '+ cleanedCount+' colors');
    if (cleaned) {
        yield put(calculate());
        yield false;
    }
}

function* draw(){
    yield put(setStatus(i18next.t('status.draw')));
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const w = yield select(state => state.adjusted.width);
    const h = yield select(state => state.adjusted.height);
    const pixels = yield select(state => state.pattern.pixels);
    const singles = yield select(state => state.pattern.singles);
    const palette = yield select(state => state.pattern.palette);
    const selected = yield select(state => state.pattern.selectedColor);
    const showSingles = yield select(state => state.pattern.showSingles);
    canvas.width = w*2;
    canvas.height = h*2;
    ctx.clearRect(0, 0, w*2, h*2);

    for (let i = 0; i < pixels.length; i++) {
        const x = i % w;
        const y = Math.floor(i / w);
        let opacity = 'FF';
        if (showSingles) {
            const isSingle = singles[i] !== undefined && singles[i];
            const isSelected = selected!== null && pixels[i] === selected;
            if ( (isSingle && isSelected) || (isSingle && selected === null) ) {
                opacity = 'FF';
            } else {
                opacity = '44';
            }
        } else {
            opacity = selected !== null && pixels[i] !== selected? "44" : "FF";
        }
        if (selected !== null) {
            if (chroma(selected).luminance() < 0.5) {
                cross(ctx, x, y, '#ffffff');
            } else {
                cross(ctx, x, y, '#333333');
            }
        }
        if (!palette[pixels[i]].code.includes("+")) {
            cross(ctx, x, y, pixels[i]+opacity);
        } else {
            blend(ctx, x, y, palette[pixels[i]].hexA+opacity, palette[pixels[i]].hexB+opacity);
        }
    }

    yield put(setRender(canvas.toDataURL()));
    yield put(setStatus(null));
}

function cross(ctx, x, y, color) {
    ctx.fillStyle = color;
    ctx.fillRect(x*2, y*2, 2, 2);
}

function blend(ctx, x, y, color1, color2) {
    ctx.fillStyle = color1;
    ctx.fillRect(x*2, y*2, 2, 2);
    ctx.fillStyle = color2;
    ctx.fillRect(x*2, y*2, 1, 1);
    ctx.fillRect(x*2+1, y*2+1, 1, 1);
}

function* createBlends(action) {
    const palette = yield select(state => state.pattern.palette);
    const codes = Object.keys(palette);

    if (action.payload === false) {
        for (let i =0; i<codes.length; i++) {
            if (palette[codes[i]].code.includes("+")) {
                yield put(removeColor(codes[i]));
            }
        }
        yield put(calculate());
    } else {
        let blends = {};

        for (let i = 0; i < codes.length - 1; i++) {
            for (let j = i + 1; j < codes.length; j++) {
                if (chroma.deltaE(codes[i], codes[j]) <= maxDistance(codes.length)) {
                    let mix = chroma.mix(codes[i], codes[j], 0.5, 'lab').hex();
                    blends[mix] = {
                        code: palette[codes[i]].code + "+" + palette[codes[j]].code,
                        name: 'blend',
                        hex: mix,
                        hexA: codes[i],
                        hexB: codes[j],
                        R: chroma(mix).get('rgb.r'),
                        G: chroma(mix).get('rgb.g'),
                        B: chroma(mix).get('rgb.b'),
                        h: parseFloat(chroma(mix).get('lch.l').toFixed(2)),
                        x: 0
                    }
                }
            }
        }
        yield put(setBlends(blends));
    }
}

function* customPalette(action) {
    let custom = action.payload.split(',').map(c => c.trim());
    let newPalette = {};
    for (let code in DMC) {
        if (custom.includes(DMC[code].code)) {
            newPalette[code] = DMC[code];
        }
    }
    yield put(setPalette(newPalette));
    yield put(setUseBlends(false));
}

export default function* patternSaga() {
    yield takeLatest( calculate.type, calculatePattern );
    yield takeLatest( findSingles.type, calcSingles );
    yield takeLatest( fixSingles.type, calcFixSingles );
    yield takeLatest( setUseBlends.type, createBlends );
    yield takeLatest( setSelectedColor.type, draw );
    yield takeLatest( setShowSingles.type, draw );
    yield takeLatest( setCustomPalette.type, customPalette );
}
